<template>
  <div class="survey-container eb-font-light" :class="{ fillError: fillError }">
    <!-- progress bar -->
    <div class="quantityOfQuestions" :class="{ hideProgress: hideProgress }">
      {{ "Step " + (currentQuestionIndex + 1) + " of " + questions.length }}
    </div>
    <div class="progress" :class="{ hideProgress: hideProgress }">
      <div class="progress-bar" role="progressbar" :style="{ width: progress + '%' }" aria-valuenow="" aria-valuemin="0" aria-valuemax="100">
        <div class="percentage">{{ progressToShow + "%" }}</div>
      </div>
    </div>
    <div v-if="fillError" class="errorMessage">დაფიქსირდა შეცდომა. დეტალები იხილე ქვემოთ.</div>
    <!-- current question -->
    <div v-if="currentQuestion">
      <p class="beforeQuestion text-font20 eb-font-bold">ბოლო 12 თვის მანძილზე...</p>
      <div :class="{ errorField: fillError }">
        <h2 class="question text-font24">{{ currentQuestion.question }}</h2>
        <ul class="answers-container">
          <li v-for="(answer, index) in currentQuestion.answers" :key="index" :class="{ active: activeAnswer === index + 1 }" class="answer-item text-font14" @click="getCurrentAnswerId(index)">
            {{ answer.text }}
          </li>
        </ul>
        <p class="errorText" v-if="fillError">ველის შევსება აუცილებელია.</p>
      </div>
    </div>

    <!-- final screen -->
    <div class="result-container" v-else>
      <div class="result-desc">
        <h2>შენ აიღე {{ resultNumber }}% აღნიშნული შედეგების შემთხვევაში:</h2>
        {{
          resultNumber > 50
            ? "ვლინდება აზარტულ თამაშებზე დამოკიდებულების და პრობლემური თამაშის მაღალი რისკი. გახსოვდეს, რომ თამაშის მიზანი შეიძლება იყოს მხოლოდ გართობა.  ითამაშე მხოლოდ იმ ფულით, რაც გაქვს და შეგიძლია წააგო. არასოდეს ისესხო ფული თამაშისთვის და ითამაშე მხოლოდ სიამოვნებისთვის. ."
            : resultNumber === 0
            ? "ტესტში 0 ქულა აიღე და არ შეგეშინდეს, აზარტულ თამაშებზე დამოკიდებულება არ გემუქრება.."
            : "აზარტული ხარ და ხანდახან რისკავ ხოლმე. ამ შემთხვევაში აზარტულ თამაშებზე დამოკიდებულების განვითარების საშუალო რისკი არსებობს. არ იფიქრო თამაშზე, როგორც ფულის შოვნის საშუალებაზე. ითამაშე მხოლოდ სიამოვნებისთვის. ."
        }}
      </div>
      <p class="desc">თუ თვითშეფასების ტესტის პასუხით კმაყოფილი არ ხარ, <router-link :to="{name: 'responsibleGaming', hash: '#gamingtools'}">მაშინ პასუხისმგებლობიანი თამაშის ინსტრუმენტებს გაეცანი.</router-link></p>
    </div>

    <!-- navigation buttons -->
    <div class="button-container text-font16" :class="{ hideProgress: hideProgress }">
      <button class="btn btn-secondary" :class="{ showPrev: previousQuestion }" @click="previous">წინა</button>
      <button class="btn btn-primary" :class="{ hideNext: !currentQuestion }" v-if="currentQuestion" @click="next">
        {{ changeButtonName ? "შედეგების ნახვა" : "შემდეგი" }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuizComponent",
  data() {
    return {
      currentQuestionIndex: 0,
      currentAnswerId: null,
      resultPercent: 0,
      questions: [
        {
          id: 1,
          question: "დაგიდია იმაზე მეტი, რისი საშუალებაც გქონდა?",
          answers: [
            { id: 1, text: "არასდროს" },
            { id: 2, text: "ხანდახან" },
            { id: 3, text: "ხშირად" },
            { id: 4, text: "თითქმის ყოველთვის" },
          ],
        },
        {
          id: 2,
          question:
            "დაგჭირვებია დიდ თანხებზე თამაში ძველებური აზარტის გრძნობის მისაღებად?",
          answers: [
            { id: 1, text: "არასდროს" },
            { id: 2, text: "ხანდახან" },
            { id: 3, text: "ხშირად" },
            { id: 4, text: "თითქმის ყოველთვის" },
          ],
        },
        {
          id: 3,
          question:
            "თამაშის დროს, მიბრუნებულხარ მეორე დღეს წაგებული თანხის ამოსაღებად?",
          answers: [
            { id: 1, text: "არასდროს" },
            { id: 2, text: "ხანდახან" },
            { id: 3, text: "ხშირად" },
            { id: 4, text: "თითქმის ყოველთვის" },
          ],
        },
        {
          id: 4,
          question: "გისესხია ფული ან გაგიყიდია რამე სათამაშოდ?",
          answers: [
            { id: 1, text: "არასდროს" },
            { id: 2, text: "ხანდახან" },
            { id: 3, text: "ხშირად" },
            { id: 4, text: "თითქმის ყოველთვის" },
          ],
        },
        {
          id: 5,
          question:
            "გიფიქრია, რომ აზარტულ თამაშებზე დამოკიდებულების პრობლემა გაქვს?",
          answers: [
            { id: 1, text: "არასდროს" },
            { id: 2, text: "ხანდახან" },
            { id: 3, text: "ხშირად" },
            { id: 4, text: "თითქმის ყოველთვის" },
          ],
        },
        {
          id: 6,
          question:
            "გამოუწვევია აზარტულ თამაშებს ჯანმრთელობის პრობლემები, როგორიცაა სტრესი და ნერვული აშლილობა?",
          answers: [
            { id: 1, text: "არასდროს" },
            { id: 2, text: "ხანდახან" },
            { id: 3, text: "ხშირად" },
            { id: 4, text: "თითქმის ყოველთვის" },
          ],
        },
        {
          id: 7,
          question: "უთქვამს ვინმეს, რომ აზარტულ თამაშებზე ხარ დამოკიდებული?",
          answers: [
            { id: 1, text: "არასდროს" },
            { id: 2, text: "ხანდახან" },
            { id: 3, text: "ხშირად" },
            { id: 4, text: "თითქმის ყოველთვის" },
          ],
        },
        {
          id: 8,
          question: "თამაშის გამო ფინანსური პრობლემები შეგქმნია?",
          answers: [
            { id: 1, text: "არასდროს" },
            { id: 2, text: "ხანდახან" },
            { id: 3, text: "ხშირად" },
            { id: 4, text: "თითქმის ყოველთვის" },
          ],
        },
        {
          id: 9,
          question: "აზარტული თამაშების გამო თავი დამნაშავედ გიგრძნია?",
          answers: [
            { id: 1, text: "არასდროს" },
            { id: 2, text: "ხანდახან" },
            { id: 3, text: "ხშირად" },
            { id: 4, text: "თითქმის ყოველთვის" },
          ],
        },
      ],
      activeAnswer: 0,
      fillError: false,
      hideProgress: false,
      answersNumber: 0,
      changeButtonName: false,
      resultNumber: 0,
      prev: false,
      answers: [],
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    previousQuestion() {
      return this.currentQuestionIndex > 0;
    },
    progress() {
      return ((this.currentQuestionIndex + 1) / this.questions.length) * 100;
    },
    progressToShow() {
      return Math.floor(this.progress);
    },
  },
  methods: {
    next() {
      if (this.activeAnswer > 0) {
        this.fillError = false;
        this.currentQuestionIndex++;
        console.log(this.answers, this.currentQuestionIndex);
        if (this.answers.length < this.currentQuestionIndex) {
          this.answers.push({
            question: this.currentQuestionIndex,
            answer: this.activeAnswer,
          });
          this.prev = false;
        }
        this.activeAnswer = null;
        if (this.prev) {
          this.activeAnswer = this.answers[this.currentQuestionIndex].answer;
        }
        if (this.currentQuestionIndex == this.questions.length) {
          this.hideProgress = true;
          let perPercentage = 100 / 27;
          this.answers.forEach((item) => {
            item.answer = item.answer - 1;
          });
          let sumOfArray = this.answers.reduce(
            (acc, obj) => acc + obj.answer,
            0
          );
          this.resultNumber = Math.ceil(sumOfArray * perPercentage);
        } else if (this.currentQuestionIndex == this.questions.length - 1) {
          this.changeButtonName = true;
        }
      } else {
        this.fillError = true;
      }
    },
    previous() {
      this.activeAnswer = this.answers[this.currentQuestionIndex - 1].answer;
      console.log(
        this.answers[this.currentQuestionIndex - 1].answer,
        this.currentQuestionIndex - 1,
        "active answer"
      );
      this.currentQuestionIndex--;
      this.prev = true;
      this.fillError = false;
      this.changeButtonName = false;
    },
    getCurrentAnswerId(index) {
      this.currentAnswerId = index;
      this.activeAnswer = index + 1;
      if (this.prev) {
        this.answers[this.currentQuestionIndex].answer = this.currentAnswerId;
        console.log("different", this.answers, this.currentQuestionIndex);
      }
    },
  },
};
</script>

<style lang="scss">
.survey-container {
  max-width: 800px;
  margin: 0 auto;
  .result-container {
    padding: 20px 0;
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    .result-desc {
      font-size: 22px;
      line-height: 1.2;
      text-align: center;
      margin-top: 15px;
    }
    .desc {
      text-align: center;
      font-size: 16px;
      line-height: 1.2;
      order: 2;
      color: #000;
      margin-top: 20px;

      a {
        color: rgba(0, 162, 175, 1);
      }
    }
  }
  &.fillError {
    .errorMessage {
      margin-top: 40px;
      color: #790000;
      font-size: 1em;
      font-weight: 700;
      margin-bottom: 25px;
      border-top: 2px solid #790000;
      border-bottom: 2px solid #790000;
      padding: 16px 0;
      clear: both;
      width: 100%;
      text-align: center;
    }
    .errorField {
      background-color: rgba(255, 223, 224, 0.25);
      margin-bottom: 6px !important;
      border-top: 1px solid #c89797;
      border-bottom: 1px solid #c89797;
      padding-bottom: 6px;
      padding-top: 8px;
      padding: 10px 0;
      .errorText {
        color: #790000;
        font-weight: 700;
        letter-spacing: normal;
        margin: 10px;
        font-size: 12px;
        text-align: left;
      }
      .question {
        color: #790000;
      }
      .answer-item {
        color: #790000;
        &.active {
          color: #fff;
        }
      }
    }
  }
  .answers-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch !important;
    text-align: center;
    align-items: flex-start;
    padding-top: 10px;
  }
  .answer-item {
    padding: 12px 20px !important;

    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    max-width: none;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    margin: 5px;
    transition: 0.2s ease-in all;
    text-align: center;
    cursor: pointer;
    &.active {
      background: #00a2af;
      color: #fff;
    }
  }
  .quantityOfQuestions {
    text-align: left;
    padding: 5px 10px;
    font-size: 13px;
    color: #303132;
    letter-spacing: -0.7px;
    font-weight: 400;
    &.hideProgress {
      display: none;
    }
  }
  .question {
    text-align: left;
    padding: 5px 10px;
  }
  .beforeQuestion {
    line-height: 36px;
    letter-spacing: -0.7px;
    color: #303132;
    text-align: left;
    padding: 5px 10px;
  }
}
.button-container {
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  border-top: 1px dotted #cecece;
  margin-top: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;

  &.hideProgress {
    display: none;
  }
  .btn-primary {
    color: #00a2af;
    border-width: 1px;
    background: none;
    border: 1px solid #00a2af;
    border-radius: 50px;
    padding: 15px 30px;
    font-weight: bold;
    float: right;
    letter-spacing: 2px;
    font-size: 16px;
    cursor: pointer;
    opacity: 1;
    &.hideNext {
      opacity: 0;
    }
  }
  .btn-secondary {
    color: #00a2af;
    border-width: 1px;
    background: none;
    border: 1px solid #00a2af;
    border-radius: 50px;
    padding: 15px 30px;
    font-weight: bold;
    float: left;
    letter-spacing: 2px;
    font-size: 16px;
    cursor: pointer;
    opacity: 0;
    &.showPrev {
      opacity: 1;
    }
  }
}

.progress {
  background-color: #c6c6c6;
  border: 10px solid #e5e5e5;
  border-radius: 20px;
  height: 44px;
  overflow: hidden;
  box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  &.hideProgress {
    display: none;
  }
  .progress-bar {
    height: 44px;
    background-color: #00a2af;
    vertical-align: middle;
    background: repeating-linear-gradient(
        -45deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 10px,
        rgba(0, 0, 0, 0.1) 10px,
        rgba(0, 0, 0, 0.1) 20px
      ),
      linear-gradient(to bottom, rgba(0, 162, 175, 1), rgba(0, 0, 0, 0.3));
    border-radius: 20px 4px 4px 20px;
    width: 11%;
    position: relative;
    .percentage {
      position: absolute;
      right: 10px;
      color: #fff;
      font-size: 13px;
      top: 14px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .answers-container {
    flex-direction: column;
  }
  .percentage {
    font-size: 12px;
    top: 15px !important;
    right: 3px !important;
  }
  .button-container {
    flex-direction: column-reverse;

    .btn-secondary {
      color: #878787;
      border: none;
      text-decoration: underline;
    }
  }

  .survey-container {
    .beforeQuestion {
      padding: 5px 0px;
      margin-top: 20px;
    }
    .question {
      padding: 5px 0px;
    }
  }
}
</style>
