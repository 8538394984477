<template>
  <div class="about-component">
    <FixedScrollingMenuComponent :fixedMenuItems=aboutFixedMenuItems></FixedScrollingMenuComponent>
    <div class="header" id="aboutHeader">
      <div class="header-bg"></div>
      <div class="wrapper">
        <div class="header-text">
          <div class="header-text-inner">
            <h3 class="isTitle font-hl-bold">ბეთსონ ჯგუფი</h3>
            <div class="isDesc font-hl-roman">
              <div class="betsson-items">
                <div class="betsson-item user">
                  <div class="betsson-item-img"><i class="icon icon-users"></i></div>
                  <div class="betsson-item-count" ref="userCount">
                    <animate-number :from=userCount :to=maxUserCount duration="1000" easing="easeOutQuad"></animate-number>
                  </div>
                  <div class="betsson-item-text">ადამიანი</div>
                </div>
                <div class="betsson-item globe">
                  <div class="betsson-item-img"><i class="icon icon-icon_globe_alt"></i></div>
                  <div class="betsson-item-count" ref="globeCount">
                    <animate-number :from=globeCount :to=maxGlobeCount duration="1000" easing="easeOutQuad"></animate-number>
                  </div>
                  <div class="betsson-item-text">ლოკაცია</div>
                </div>
                <div class="betsson-item flag">
                  <div class="betsson-item-img"><i class="icon icon-flag"></i></div>
                  <div class="betsson-item-count" ref="flagCount">
                    <animate-number :from=flagCount :to=maxFlagCount duration="1000" easing="easeOutQuad"></animate-number>
                  </div>
                  <div class="betsson-item-text">ეროვნება</div>
                </div>
                <div class="betsson-item megaphone">
                  <div class="betsson-item-img"><i class="icon icon-megaphone"></i></div>
                  <div class="betsson-item-count" ref="megaphoneCount">
                    <animate-number :from=megaphoneCount :to=maxMegaphoneCount duration="1000" easing="easeOutQuad"></animate-number>
                  </div>
                  <div class="betsson-item-text">ბრენდი</div>
                </div>
              </div>
            </div>

            <div class="menu-btns font-hl-roman">
              <div class="menu-btn">
                <router-link :to="{name: 'about', hash: '#betssonab'}">ბეთსონის შესახებ</router-link>
              </div>
              <div class="menu-btn">
                <router-link :to="{name: 'about', hash: '#ourvalues'}">ჩვენი ღირებულებები</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="wrapper">
        <div class="about-betsson-block" id="betssonab">
          <h3 class="title font-hl-bold">რატომ ერთიანი ბეთსონი?</h3>
          <p class="subtitle font-hl-light">ევროპაბეთში და მთლიანად ბეთსონ ჯგუფში ვფიქრობთ, რომ საერთო მიზნისკენ ერთად სწრაფვას შეუძლია საუკეთესო შედეგებამდე მიგვიყვანოს. ჩვენი ერთ-ერთი ღირებულება – ერთიანი ბეთსონი, ზუსტად გამოხატავს ჩვენს
            დამოკიდებულებას კომპანიისა და ერთმანეთის მიმართ. მუდმივად ვცდილობთ, ჩვენი წვლილი შევიტანოთ საზოგადოებაში და ამას პროცესებში ჩართულ კომპანიებთან პარტნიორობითა და თანამშრომლობით ვახერხებთ.</p>

          <div class="about-betsson-desc">
            <div class="logo-image">
              <img :src="require('@/assets/about-component/about-betsson-group-logo.png')" alt="">
            </div>
            <h3 class="font-hl-bold">ბეთსონ ჯგუფის შესახებ</h3>

            <p class="font-hl-light">50 წელზე მეტია ბეთსონ ჯგუფი აზარტული თამაშების ბიზნესშია. სლოტის ერთი აპარატიდან გლობალურ ონლაინ-გეიმინგ კომპანიამდე. 2 კაციდან იდეით და ოცნებით 1700 თანამშრომლამდე. 1 ქალაქიდან 10 ოფისამდე მთელ მსოფლიოში. ერთი
              ბრენდიდან დავიწყეთ და ახლა უკვე 17 ვართ, მათ შორისაა Betsson, Betsafe, NordicBet და Europebet.</p>

            <p class="font-hl-light">დაუვიწყარი თავგადასავალია.</p>

            <p class="font-hl-light">როგორ მოვედით აქამდე? ჩვენ ვცდილობთ, რომ მოთამაშეებს საუკეთესო გამოცდილება შევთავაზოთ, გვყავდეს პასუხისმგებლობით მოთამაშე მომხმარებლები, რომლებიც სიამოვნებას მიიღებენ თამაშით. სწორედ ამიტომ პასუხისმგებლიანი თამაში
              ჩვენი ბიზნესის ამომავალი წერტილია. ჩვენ ვიყავით პირველები, ვინც პასუხისმგებლიანი თამაშის მენეჯერი დაიქირავა. ეს 2007 წელს მოხდა, დღეს კი პასუხისმგებლიანი თამაშის მთელი განყოფილება გვაქვს. სამართლიანად თამაშით, ერთად ვიმარჯვებთ. ჩვენ
              საქმეს მხოლოდ ამ პრინციპით ვაწარმოებთ. 50 წელზე მეტია ასე მოვდივართ და გვინდა კიდევ 50 წელი ასევე გავაგრძელოთ. ერთად მივაღწიოთ დასახულ მიზნებს. „ბეთსონ ჯგუფი 100 წლისაა“ ძალიან კარგად ჟღერს.</p>

            <p class="font-hl-light">ბეთსონ ჯგუფის მფლობელი <a class="light-blue" href="https://betssonab.com/">Betsson AB</a>-ია –Nasdaq Stockholm Large Cap-დან.</p>

            <h3 class="font-hl-bold">ევროპაბეთის შესახებ</h3>

            <p class="font-hl-light">ევროპაბეთი, რეგიონის ერთ-ერთი ლიდერი გემბლინგ კომპანიაა, რომელიც 2010 წელს დაარსდა. მას შემდეგ მოთამაშეებს მრავალფეროვან ონლაინ სერვისებს სთავაზობს, როგორიცაა ფსონები სპორტზე, ლაივ კაზინო, სლოტ თამაშები, კენო და
              რეგიონის პოპულარული მაგიდის თამაშები.</p>
            <p class="font-hl-light">2015 წლიდან საერთაშორისო ბრენდის Bettson Group-ის წევრია და ინდუსტრიაში ახალ ინოვაციურ იდეებსა და ტექნოლოგიებს ნერგავს.</p>
            <p class="font-hl-light">ევროპაბეთის პარტნიორები არიან: SB Betting, Betradar, Nsoft, Betgenius, Evolution gaming, GCompany, EGT, NetEnt, Novomatic, Yggdrasil, Igrosoft, iSofBet, Pragmatic, Play’n GO, Playson, Endorphina და QuickFire.</p>

          </div>

        </div>
        <div class="our-values-block" id="ourvalues">
          <h3 class="title font-hl-bold">ჩვენი ღირებულებები</h3>
          <p class="subtitle font-hl-light">კომპანიის ღირებულებები ზუსტად ასახავს ჩვენი მუშაობის პრინციპებს, მოთამაშეებთან, ინვესტრორებსა და აქციონერებთან ურთიერთობას.</p>
          <div class="boxes">
            <div class="box box-1">
              <div class="box-image">
                <img :src="require('@/assets/about-component/one-betsson.svg')" alt="">
              </div>
              <h4 class="box-title font-hl-bold">ერთიანი ბეთსონი</h4>
              <p class="box-desc font-hl-light">მთავარი ხალხია. ერთად, გუნდურად ვმუშაობთ, რათა საერთო მიზანს მივაღწიოთ. სამსახურში სიარული გვიყვარს, რადგან იქ საუკეთესო კოლეგები გველოდებიან. ჩვენ პატივს ვცემთ და მხარს ვუჭერთ ერთმანეთს. ჩვენთან კარი
                ყველასთვის ღიაა.</p>

            </div>
            <div class="box box-2">
              <div class="box-image">
                <img :src="require('@/assets/about-component/fair-play.svg')" alt="">
              </div>
              <h4 class="box-title font-hl-bold">სამართლიანი თამაში</h4>
              <p class="box-desc font-hl-light">ჩვენ ვუსმენთ და პატივს ვცემთ ჩვენს მოთამაშეებს, პარტნიორებსა და ერთმანეთს. ვმუშაობთ გამჭვირვალედ და საქმეს დიდი პასუხისმგებლობით ვეკიდებით. სამართლიანად თამაშით, ჩვენ ყველა ერთად ვიმარჯვებთ.</p>

            </div>
            <div class="box box-3">
              <div class="box-image">
                <img :src="require('@/assets/about-component/passion.svg')" alt="">
              </div>
              <h4 class="box-title font-hl-bold">საქმისადმი სიყვარული</h4>
              <p class="box-desc font-hl-light">ჩვენ გვიყვარს ის, რასაც ვაკეთებთ. გვიყვარს სამუშაო, ჩვენი გუნდი და კომპანია. საქმისა და iGaming-ისადმი ჩვენი დამოკიდებულებით, საოცარ შედეგებს ვაღწევთ. ჩვენი ენთუზიაზმი გვამოძრავებს სრული სვლით წინ.</p>

            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import FixedScrollingMenuComponent from "@/components/fixed-scrolling-menu/index.vue";
import router from "@/router";

export default {
  name: "AboutComponent",
  props: {
    isDescWidth: Boolean,
    isTabWidth: Boolean,
    isMobWidth: Boolean,
  },
  components: {
    FixedScrollingMenuComponent,
  },
  data() {
    return {
      userCount: 0,
      maxUserCount: 1700,
      globeCount: 0,
      maxGlobeCount: 10,
      flagCount: 0,
      maxFlagCount: 60,
      megaphoneCount: 0,
      maxMegaphoneCount: 17,
      aboutLongSectionObserver: null,
      aboutShortSectionObserver: null,
      incrementCounterInterval: null,
    };
  },
  methods: {},
  computed: {
    aboutFixedMenuItems() {
      return [
        {
          id: 1,
          name: "about",
          hash: "#betssonab",
          alias: "BETSSON A/B",
        },
        {
          id: 2,
          name: "about",
          hash: "#ourvalues",
          alias: "OUR VALUES",
        },
      ];
    },
  },

  mounted() {
    let aboutHeaderHeight = document.getElementById("aboutHeader").offsetHeight;
    let betssonabHeight = document.getElementById("betssonab").offsetHeight;
    let ourvaluesHeight = document.getElementById("ourvalues").offsetHeight;

    const aboutHeader = this.$scrollmagic.scene({
      triggerElement: "#aboutHeader",
      triggerHook: 0.5,
      duration: aboutHeaderHeight,
    });
    this.$scrollmagic.addScene(aboutHeader);
    aboutHeader.on("enter", (event) => {
      this.$router
        .replace({
          name: this.$route.name,
          hash: ``,
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    });

    const betssonab = this.$scrollmagic
      .scene({
        triggerElement: "#betssonab",
        triggerHook: 0.5,
        duration: betssonabHeight,
      })
      .setClassToggle("#link-1", "router-link-active");
    this.$scrollmagic.addScene(betssonab);

    const ourvalues = this.$scrollmagic
      .scene({
        triggerElement: "#ourvalues",
        triggerHook: 0.5,
        duration: ourvaluesHeight,
      })
      .setClassToggle("#link-2", "router-link-active");
    this.$scrollmagic.addScene(ourvalues);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";

.about-component {
  width: 100%;
  min-height: 100%;
  .light-blue {
    color: rgba(0, 162, 175, 1);
  }
  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 599px;
    z-index: 9;
    .header-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/about-component/about-header-bg.jpg");
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center center;
      background-size: cover;
      z-index: -1;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    .header-text {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      .header-text-inner {
        padding: 0px 25px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        .isTitle {
          color: #fcfcfc;
          font-size: 48px;
          line-height: 1.1;
          letter-spacing: -1px;
          margin-bottom: 20px;
        }
        .isDesc {
          color: #fefefe;
          margin-bottom: 35px;
          .betsson-items {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            .betsson-item {
              color: #fff;
              margin-top: 20px;
              flex: 1;
              .icon {
                font-size: 35px;
                width: auto;
                height: auto;
                line-height: auto;
              }

              .betsson-item-img {
                margin-bottom: 20px;
              }
              .betsson-item-count {
                font-size: 40px;
                margin-bottom: 10px;
              }
              .betsson-item-text {
                font-size: 16px;
              }
            }
          }
        }
        .menu-btns {
          display: flex;
          justify-content: center;
          align-items: center;
          .menu-btn {
            border: 1px solid #fff;
            border-radius: 50px;
            margin-right: 10px;
            padding: 14px 30px;
            color: #fff;
            font-size: 13px;
            background-color: transparent;
            a {
              color: #fff;
            }
            &:hover {
              background-color: rgba(0, 162, 175, 1);
              border: 1px solid rgba(0, 162, 175, 1);
              cursor: pointer;
            }
          }
        }
        @media only screen and (max-width: 1200px) {
          padding: 0px 15px;
          width: 80%;
        }
        @media only screen and (max-width: 880px) {
          padding: 0px 15px;
          width: 90%;
        }
        @media only screen and (max-width: 768px) {
          padding: 0px 15px;
          .isTitle {
            font-size: 30px;
          }
          .isDesc {
            display: none;
          }
          .menu-btns {
            display: none;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      min-height: 262px;
    }
  }
  .content {
    .about-betsson-block,
    .our-values-block {
      padding-top: 80px;
      padding-bottom: 80px;
      text-align: center;
      background-color: transparent;
      scroll-margin-top: 36px;
      .title {
        color: #303132;
        line-height: 1.1;
        margin-bottom: 20px;
        font-size: 48px;
      }
      .subtitle {
        color: #878787;
        font-size: 18px;
        line-height: 1.7;
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 50px;
      }
      @media only screen and (max-width: 1024px) {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      @media only screen and (max-width: 768px) {
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
        max-width: 440px;
        margin: 0 auto;
      }
      @media only screen and (max-width: 500px) {
        scroll-margin-top: 16px;
      }
    }
    .about-betsson-block {
      background-color: transparent;

      .about-betsson-desc {
        color: #878787;
        border: 1px solid rgba(221, 221, 221, 1);
        min-height: 300px;
        padding: 40px 30px;
        text-align: left;
        font-size: 16px;
        // line-height: 22px;
        line-height: 1.4;

        .logo-image {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 30px;
          width: 100%;
          max-width: 334px;
          img {
            max-width: 100%;
          }
        }
        h3 {
          margin-bottom: 20px;
        }
        p {
          margin-bottom: 20px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        .title {
          font-size: 30px;
        }
      }
    }
    .our-values-block {
      background-color: rgba(245, 245, 245, 1);
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100vw;
        background: inherit;
        top: 0;
        left: -50vw;
        right: -50vw;
        margin-right: auto;
        margin-left: auto;
        z-index: -1;
      }
      .title {
        font-size: 48px;
      }
      .boxes {
        display: flex;
        justify-content: center;
        align-items: stretch;
        .box {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          border: 1px solid #dddddd;
          padding: 30px 25px;
          text-align: left;
          font-size: 16px;
          color: #878787;
          .box-image {
            width: 195px;
            height: 195px;
            align-self: center;
            margin-bottom: 30px;
            img {
              max-width: 100%;
              height: auto;
            }
          }
          .box-title {
            margin-bottom: 20px;
          }
          .box-desc {
            margin-bottom: 30px;
          }
          .box-link {
            display: inline-block;
            font-size: 13px;
            margin-top: auto;
            background-color: transparent;
            border: 1px solid rgba(0, 162, 175, 1);
            color: rgba(0, 162, 175, 1);
            border-radius: 50px;
            padding: 15px 30px;
            &:hover {
              background-color: rgba(0, 162, 175, 1);
              color: #fff;
            }
          }
          &.box-2 {
            border-left: 0 none;
            border-right: 0 none;
          }
        }
      }
      // .footer {
      //   margin-top: 50px;

      //   .footer-text {
      //     color: #878787;
      //     font-size: 16px;
      //     margin-bottom: 20px;
      //     font-style: italic;
      //   }
      //   .footer-link {
      //     display: inline-block;
      //     font-size: 13px;
      //     background-color: transparent;
      //     border: 1px solid rgba(0, 162, 175, 1);
      //     color: rgba(0, 162, 175, 1);
      //     border-radius: 50px;
      //     padding: 15px 30px;
      //     &:hover {
      //       background-color: rgba(0, 162, 175, 1);
      //       color: #fff;
      //     }
      //   }
      // }
      @media only screen and (max-width: 768px) {
        .title {
          font-size: 30px;
        }
        .boxes {
          flex-direction: column;
          .box {
            &.box-2 {
              border: 1px solid #dddddd;
              border-top: 0 none;
              border-bottom: 0 none;
            }
          }
        }
      }
    }
  }
}
</style>
