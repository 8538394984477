<template>
  <div class="nav" :class="[{fixed: HeaderFixed && !onArticlePage}, {'article-page-class': onArticlePage}]">
    <div class="wrapper">
      <div class="nav-inner">
        <div class="nav-logo" @click="$route.name == 'main' ? backToTop() : null">
          <router-link :to="{ name: 'main'}">
            <img :src="!HeaderFixed ? require('@/assets/navbar-component/logoLight.png') : require('@/assets/navbar-component/logoDark.png')" alt="Logo">
          </router-link>
        </div>
        <div class="nav-menu-toggle" @click="toggleMenu()" :class="{'clicked': showMobMenu}">
          <span class="first"></span>
          <span class="second"></span>
          <span class="third"></span>
        </div>
        <div class="nav-menu">
          <p class="link" @click="$route.name == 'main' ? backToTop() : null">
            <router-link :to="{ name: 'main'}" exact exact-path>მთავარი</router-link>
          </p>
          <p class="link" @click="$route.name == 'about' ? backToTop() : null">
            <router-link :to="{ name: 'about'}">ჩვენ შესახებ</router-link>
          </p>
          <p class="link" @click="$route.name == 'responsibleGaming' ? backToTop() : null">
            <router-link :to="{ name: 'responsibleGaming'}">პასუხისმგებლიანი თამაში</router-link>
          </p>
          <p class="link" @click="$route.name == 'csr' ? backToTop() : null">
            <router-link :to="{ name: 'csr'}">საზოგადოებრივი პროექტები</router-link>
          </p>
          <p class="link" @click="$route.name == 'collaborations' ? backToTop() : null">
            <router-link :to="{ name: 'collaborations'}">პარტნიორები</router-link>
          </p>
          <p class="link" @click="$route.name == 'report' ? backToTop() : null">
            <router-link :to="{ name: 'report'}">ანგარიში</router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="mob-menu" :class="{opened: showMobMenu}">
      <p class="link" @click="$route.name == 'main' ? backToTopNav() : null">
        <router-link :to="{ name: 'main'}" exact exact-path>მთავარი</router-link>
      </p>
      <p class="link" @click="$route.name == 'about' ? backToTopNav() : null">
        <router-link :to="{ name: 'about'}">ჩვენ შესახებ</router-link>
      </p>
      <p class="link" @click="$route.name == 'responsibleGaming' ? backToTopNav() : null">
        <router-link :to="{ name: 'responsibleGaming'}">პასუხისმგებლიანი თამაში</router-link>
      </p>
      <p class="link" @click="$route.name == 'csr' ? backToTopNav() : null">
        <router-link :to="{ name: 'csr'}">საზოგადოებრივი პროექტები</router-link>
      </p>
      <p class="link" @click="$route.name == 'collaborations' ? backToTopNav() : null">
        <router-link :to="{ name: 'collaborations'}">პარტნიორები</router-link>
      </p>
      <p class="link" @click="$route.name == 'report' ? backToTopNav() : null">
        <router-link :to="{ name: 'report'}">ანგარიში</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import $ from "jquery";
export default {
  name: "NavbarComponent",
  props: {
    HeaderFixed: Boolean,
    showMobMenu: Boolean,
    toggleMenu: Function,
    onArticlePage: Boolean,
    backToTop: Function,
    backToTopNav: Function,
  },
  data() {
    return {};
  },
  methods: {},

  mounted() {},
};
</script>

<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";
.nav {
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 89px;
  z-index: 999;
  .nav-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20px;
    width: 100%;
    .nav-logo {
      max-width: 230px;
      flex: 1;
      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
      @include interpolate(max-width, 320px, 480px, 190px, 230px);
      @media only screen and (max-width: 480px) {
        flex: unset;
      }
    }
    .nav-menu {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: stretch;
      .link {
        font-size: 14px;
        a {
          padding: 0px 10px;
          display: inline-flex;
          align-items: center;
          height: 69px;
          text-decoration: none;
          color: #f5f5f5;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            opacity: 0;
            bottom: 0;
            left: 10px;
            width: calc(100% - 20px);
            height: 2px;
            background-color: rgba(0, 0, 0, 0.1);
            transition: opacity 0.3s, transform 0.3s;
            transform: translateY(10px);
          }
          &:hover {
            color: #d6d6d6;
            &::after {
              opacity: 1;
              background-color: #d6d6d6;
              transform: translateY(0px);
            }
          }

          &.router-link-active {
            color: #d6d6d6;
            &::after {
              opacity: 1;
              background-color: transparent;
              transform: translateY(0px);
            }
          }
        }
      }
      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }
    .nav-menu-toggle {
      flex: 1;
      justify-content: flex-end;
      color: #fff;
      display: none;
      cursor: pointer;
      background-color: transparent;
      padding: 0;
      position: relative;
      z-index: 3;
      width: 30px;
      height: 30px;
      margin-top: 10px;
      transform: translateZ(0);
      backface-visibility: hidden;

      span {
        display: block;
        height: 4px;
        width: 30px;
        background-color: #f5f5f5;
        margin: 0;
        transition: transform 0.5s, top 0.5s, width 0.2s;
        position: absolute;
        &.first {
          top: -10px;
        }
        &.second {
        }
        &.third {
          top: 10px;
        }
      }
      &:hover span {
        background-color: #fff;
      }
      &.clicked {
        span {
          &.first {
            transform: rotate(-45deg);
            top: 0px;
            background-color: #fff;
          }
          &.second {
            transform: scaleX(0);
          }
          &.third {
            transform: rotate(45deg);
            top: -0px;
            background-color: #fff;
          }
        }
      }
      @media only screen and (max-width: 1200px) {
        display: inline-flex;
      }
    }

    @media only screen and (max-width: 480px) {
      align-items: stretch;
    }
    @media only screen and (max-width: 370px) {
      width: 98%;
    }
  }
  .mob-menu {
    position: absolute;
    width: 100%;
    top: 100%;
    background-color: #fff;
    padding-left: 20px;
    transform: scaleY(0);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s linear;
    transform-origin: top;
    z-index: 999;
    .link {
      border-bottom: 1px solid #efefef;
      a {
        font-size: 13px;
        line-height: 40px;
        letter-spacing: 1px;
        color: #232323;
        &.router-link-active {
          color: #a3a3a3;
          &::after {
            background-color: rgba(214, 214, 214, 0);
          }
        }
      }
    }
    &.opened {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
    }
  }
  &.fixed {
    position: fixed;
    background-color: #fff;
    height: 74px;
    animation: navbarSlideDown 0.2s normal forwards linear;
    .nav-inner {
      margin-top: 5px;
      height: 69px;
      .nav-menu {
        .link {
          a {
            color: rgb(142, 142, 142);
            &::after {
              bottom: 7px;
            }
            &:hover {
              color: #d6d6d6;
              &::after {
                background-color: rgb(214, 214, 214);
              }
            }
            &.router-link-active {
              color: #d6d6d6;
              &::after {
                background-color: rgba(214, 214, 214, 0);
              }
            }
          }
        }
      }
      .nav-menu-toggle {
        span {
          background-color: rgb(142, 142, 142);
        }
        &:hover span {
          background-color: rgb(142, 142, 142);
        }
        &.clicked {
          span {
            &.first {
              transform: rotate(-45deg);
              top: 0px;
              background-color: rgb(142, 142, 142);
            }
            &.second {
              transform: scaleX(0);
            }
            &.third {
              transform: rotate(45deg);
              top: -0px;
              background-color: rgb(142, 142, 142);
            }
          }
        }
      }
    }
    @media only screen and (max-width: 480px) {
      height: 55px;
      .nav-inner {
        margin-top: 12px;
        height: 100%;
      }
    }
  }
  &.article-page-class {
    position: absolute;
    background-color: #fff;
    height: 74px;
    .nav-inner {
      margin-top: 5px;
      height: 69px;
      .nav-logo {
        max-width: 230px;
        flex: 1;
        img {
          content: url("../../assets/navbar-component/logoDark.png");
        }
      }
      .nav-menu {
        .link {
          a {
            color: rgb(142, 142, 142);
            &::after {
              bottom: 7px;
            }
            &:hover {
              color: #d6d6d6;
              &::after {
                background-color: rgb(214, 214, 214);
              }
            }

            &.router-link-active {
              color: #d6d6d6;
              &::after {
                background-color: transparent;
              }
            }
          }
        }
      }
      .nav-menu-toggle {
        span {
          background-color: rgb(142, 142, 142);
        }
        &:hover span {
          background-color: rgb(142, 142, 142);
        }
        &.clicked {
          span {
            &.first {
              transform: rotate(-45deg);
              top: 0px;
              background-color: rgb(142, 142, 142);
            }
            &.second {
              transform: scaleX(0);
            }
            &.third {
              transform: rotate(45deg);
              top: -0px;
              background-color: rgb(142, 142, 142);
            }
          }
        }
      }
    }
    @media only screen and (max-width: 480px) {
      height: 55px;
      .nav-inner {
        margin-top: 12px;
        height: 100%;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    height: 55px;
    .nav-inner {
      margin-top: 12px;
      height: 100%;
    }
  }
}
</style>