<template>
  <div class="main-component">
    <FixedScrollingMenuComponent :fixedMenuItems=homeFixedMenuItems></FixedScrollingMenuComponent>
    <div class="header" id="homeHeader">
      <div class="header-bg"></div>
      <div class="wrapper">
        <div class="header-text">
          <div class="header-text-inner">
            <h3 class="isTitle font-hl-bold">სამართლიანი თამაში</h3>
            <div class="isDesc font-hl-roman">
              <p>ევროპაბეთში, ისევე როგორც მთელს ბეთსონში, ყველას საერთო ფასეულობები გვაქვს: <span class="font-hl-bold">ერთიანი ბეთსონი, სამართლიანი თამაში და საქმისადმი სიყვარული.</span></p>
              <p>ეს გვერდი საზოგადოებაში ცნობიერების ამაღლებას, პასუხისმგებლიანი თამაშის პოპულარიზაციასა და პარტნიორებთან ურთიერთობას ეხება.</p>
            </div>

            <div class="menu-btns font-hl-roman">
              <div class="menu-btn">
                <router-link :to="{name: 'main', hash: '#responsiblegame'}">პასუხისმგებლიანი თამაში</router-link>
              </div>
              <div class="menu-btn">
                <router-link :to="{name: 'main', hash: '#socialresponsibility'}">საზოგადოებრივი პროექტები</router-link>
              </div>
              <div class="menu-btn">
                <router-link :to="{name: 'main', hash: '#collaborations'}">პარტნიორები</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="wrapper">
        <div class="responsible-game-block" id="responsiblegame">
          <h3 class="title font-hl-bold">ითამაშე მხოლოდ სიამოვნებისთვის</h3>
          <p class="subtitle font-hl-light">აზარტული თამაშები უხსოვარი დროიდან გართობის ერთ-ერთი საშუალებას წარმოადგენს. ჩვენ გვსურს, რომ მოთამაშეებმა ჩვენთან მხოლოდ სიამოვნებისთვის ითამაშონ და სწორედ ამიტომ ვატარებთ პასუხისმგებლიანი თამაშის
            პოლიტიკას.</p>
          <div class="boxes">
            <div class="box box-1">
              <div class="box-image">
                <img :src="require('@/assets/home-component/box-1-img.svg')" alt="">
              </div>
              <h4 class="box-title font-hl-bold">პასუხისმგებლიანი თამაში</h4>
              <p class="box-desc font-hl-light">პასუხისმგებლობით თამაში ჩვენი საქმის ამომავალი წერტილია და მას განსაკუთრებულ ყურადღებას ვუთმობთ. ვიცით, რომ აზარტულ თამაშებს თან რისკი სდევს. მოთამაშეების უმრავლესობას აზარტული თამაშები ცხოვრებაში
                მრავალფეროვნებასა
                და გასართობს სთავაზობს, თუმცა ზოგისთვის, შესაძლოა, პრობლემატური გახდეს.</p>
              <router-link :to="{name: 'responsibleGaming', hash: '#betsson'}" class="box-link font-hl-light">გაიგე მეტი</router-link>
            </div>
            <div class="box box-2">
              <div class="box-image">
                <img :src="require('@/assets/home-component/box-2-img.svg')" alt="">
              </div>
              <h4 class="box-title font-hl-bold">ხელსაწყოები</h4>
              <p class="box-desc font-hl-light">მოთამაშეების კეთილდღეობისთვის შექმნილი გვაქვს გარკვეული ხელსაწყოები, რომლებიც მათ პასუხისმგებლიან თამაშში ეხმარება. დამატებითი ინფორმაციისთვის მიყევით ბმულს</p>
              <router-link :to="{name: 'responsibleGaming', hash: '#gamingtools'}" class="box-link font-hl-light">გაიგე მეტი</router-link>
            </div>
            <div class="box box-3">
              <div class="box-image">
                <img :src="require('@/assets/home-component/box-3-img.svg')" alt="">
              </div>
              <h4 class="box-title font-hl-bold">რჩევები</h4>
              <p class="box-desc font-hl-light">იმისათვის, რომ აზარტული თამაშები გასართობი და უსაფრთხო იყოს, გაეცანით საინტერესო ინფორმაციასა და რჩევებს. თამაშის პროცესის უკეთ გაცნობით და შესწავლით რისკებს მაქსიმალურად შეამცირებთ.</p>
              <router-link :to="{name: 'responsibleGaming', hash: '#gamingtips'}" class="box-link font-hl-light">წაიკითხე რჩევები</router-link>
            </div>
          </div>
          <div class="footer">
            <p class="footer-text font-hl-light">გაინტერესებს რას ვაკეთებთ და როგორ ვცდილობთ, რომ მოთამაშეებმა ჩვენთან მხოლოდ სიამოვნებისთვის ითამაშონ?</p>
            <router-link :to="{name: 'responsibleGaming'}" class="footer-link font-hl-light">გაიგე მეტი</router-link>
          </div>
        </div>
        <div class="social-responsibility-block" id="socialresponsibility">
          <h3 class="title font-hl-bold">ჩვენ ვზრუნავთ</h3>
          <p class="subtitle font-hl-light">როგორც ევროპაბეთი, ასევე მთლიანად ბეთსონ ჯგუფი ვცდილობთ საზოგადოების აქტიური წევრები ვიყოთ. ჩვენი კომპანიის ერთ-ერთი ღირებულება „ერთიანი ბეთსონია“ და სწორედ ერთად ვცდილობთ რეალობის უკეთესობისკენ შეცვლას.
          </p>
          <div class="home-thumbs-outer">
            <swiper ref="homeThumbsSwiper" :options="homeThumbsSwiperOptions()" class="home-thumbs-wrapper">
              <swiper-slide v-for="(item, index) in articlesList" :key="index">
                <div class="home-swiper-thumb">
                  <router-link :to="{name:item.articleUrl, params: {slug: item.slug}
                  }">
                    <img :src="require(`@/assets/home-component/${item.homeSliderimageSrc}`)" />
                    <div class="thumb-overlay">
                      <div class="thumb-bg">
                        <div class="thumb-title-wrap">
                          <div class="thumb-title font-hl-bold" :class="`thumb-title-${index}`">{{item.title}}</div>
                          <div class="portfolio-item-cats font-hl-light">
                            <span>Georgia</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>
              <div v-show="!isMobWidth" class="home-swiper-btns swiper-button-prev" slot="button-prev">
                <i class="icon icon-arrow_carrot-left"></i>
              </div>
              <div v-show="!isMobWidth" class="home-swiper-btns swiper-button-next" slot="button-next">
                <i class="icon icon-arrow_carrot-right"></i>
              </div>
              <div v-show="isMobWidth" class="home-swiper-pagination swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <div class="footer">
            <p class="footer-text font-hl-light">გინდა მეტი გაიგო ჩვენს სოციალურ პროექტებზე?</p>
            <router-link :to="{name: 'csr'}" class="footer-link font-hl-light">გაეცანი ყველა პროექტს</router-link>
          </div>
        </div>
        <div class="collaborations-block" id="collaborations">
          <h3 class="title font-hl-bold">ჩვენ ვთანამშრომლობთ</h3>
          <p class="subtitle font-hl-light">
            გუნდებთან და სპორტსმენებთან თანამშრომლობით ვცდილობთ, აქტიურად ვიყოთ ჩართული ყოველდღიურობაში. მხარს ვუჭერთ სპორტულ კლუბებსა და ღონისძიებებს, რაც სპორტისადმი ჩვენი სიყვარულითაა განპირობებული. ვამაყობთ, რომ სპორტბეთინგში
            ვართ
            და მაქსიმალურად ვცდილობთ მოთამაშეებს სამართლიანი კოეფიციენტები, წესები და პირობები შევთავაზოთ. ჩვენ გვიყვარს ჩვენი საქმე და ინდუსტრია, რაც იმას ნიშნავს, რომ ჩვენს ამბასადორებს, პარტნიორობის ფარგლებში, მაქსიმალურ ყურადღებას ვუთმობთ.</p>
          <div class="logos-images">
            <swiper ref="homeCollaborationsSectionThumbsSwiper" :options="homeCollaborationsSectionThumbsSwiperOptions()" class="home-collaborations-section-thumbs-wrapper">
              <swiper-slide>
                <div class="logo-image">

                  <img :src="require('@/assets/home-component/Youth-Media-Union-logo.jpg')" alt="">
                  <p class="text font-hl-light">ახალგაზრდული მედია კავშირი</p>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="logo-image">
                  <img :src="require('@/assets/home-component/Federation-of-Strong-Men-of-Georgia-logo.jpg')" alt="">
                  <p class="text font-hl-light">საქართველოს ძლიერ მამაკაცთა ფედერაცია</p>
                </div>
              </swiper-slide>
              <div class="home-collaborations-section-swiper-prev-btn swiper-button-prev" slot="button-prev">
                <i class="icon icon-arrow_carrot-left"></i>
              </div>
              <div class="home-collaborations-section-swiper-next-btn swiper-button-next" slot="button-next">
                <i class="icon icon-arrow_carrot-right"></i>
              </div>
            </swiper>
          </div>
          <router-link :to="{name: 'collaborations'}" class="collaborations-link font-hl-light">გაიგე მეტი</router-link>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import FixedScrollingMenuComponent from "@/components/fixed-scrolling-menu/index.vue";
import router from "@/router";
import $ from "jquery";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
  name: "MainComponent",
  props: {
    isDescWidth: Boolean,
    isTabWidth: Boolean,
    isMobWidth: Boolean,
  },
  components: {
    Swiper,
    SwiperSlide,
    FixedScrollingMenuComponent,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      homeLongSectionObserver: null,
      homeShortLongSectionObserver: null,
    };
  },
  methods: {
    homeThumbsSwiperOptions() {
      return {
        slidesPerView: 2,
        spaceBetween: 0,
        initialSlide: 0,
        observer: true,
        observeParents: true,
        loop: true,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          600: {
            slidesPerView: 3,
          },

          800: {
            slidesPerView: 4,
          },
          1000: {
            slidesPerView: 5,
          },
        },
      };
    },
    homeCollaborationsSectionThumbsSwiperOptions() {
      return {
        slidesPerView: 1,
        spaceBetween: 0,
        initialSlide: 0,
        observer: true,
        observeParents: true,
        loop: true,
        autoplay: false,

        navigation: {
          nextEl:
            ".swiper-button-next.home-collaborations-section-swiper-next-btn",
          prevEl:
            ".swiper-button-prev.home-collaborations-section-swiper-prev-btn",
        },
        breakpoints: {
          480: {
            slidesPerView: 2,
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters({
      allarticles: "allArticles",
    }),
    articlesList() {
      return this.allarticles.slice(0, 8);
    },
    homeFixedMenuItems() {
      return [
        {
          id: 1,
          name: "main",
          hash: "#responsiblegame",
          alias: "Responsible Gaming",
        },
        {
          id: 2,
          name: "main",
          hash: "#socialresponsibility",
          alias: "Social Responsibility",
        },
        {
          id: 3,
          name: "main",
          hash: "#collaborations",
          alias: "Collaborations",
        },
      ];
    },
  },
  mounted() {
    let homeHeaderHeight = document.getElementById("homeHeader").offsetHeight;
    let responsiblegameHeight =
      document.getElementById("responsiblegame").offsetHeight;
    let socialresponsibilityHeight = document.getElementById(
      "socialresponsibility"
    ).offsetHeight;
    let collaborationsHeight =
      document.getElementById("collaborations").offsetHeight;
    const homeHeader = this.$scrollmagic.scene({
      triggerElement: "#homeHeader",
      triggerHook: 0.5,
      duration: homeHeaderHeight,
    });
    this.$scrollmagic.addScene(homeHeader);
    homeHeader.on("enter", (event) => {
      this.$router
        .replace({
          name: this.$route.name,
          hash: ``,
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    });

    const responsiblegame = this.$scrollmagic
      .scene({
        triggerElement: "#responsiblegame",
        triggerHook: 0.5,
        duration: responsiblegameHeight,
      })
      .setClassToggle("#link-1", "router-link-active");
    this.$scrollmagic.addScene(responsiblegame);

    const socialresponsibility = this.$scrollmagic
      .scene({
        triggerElement: "#socialresponsibility",
        triggerHook: 0.5,
        duration: socialresponsibilityHeight,
      })
      .setClassToggle("#link-2", "router-link-active");
    this.$scrollmagic.addScene(socialresponsibility);

    const collaborations = this.$scrollmagic
      .scene({
        triggerElement: "#collaborations",
        triggerHook: 0.5,
        duration: collaborationsHeight,
      })
      .setClassToggle("#link-3", "router-link-active");
    this.$scrollmagic.addScene(collaborations);
  },
};
</script>
<style lang="scss">
.main-component {
  width: 100%;
  min-height: 100%;

  .header {
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 9;
    .header-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/home-component/home-header-bg.jpg");
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center center;
      background-size: cover;
      z-index: -1;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
      }
    }
    .header-text {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      .header-text-inner {
        padding: 0px 25px 0;
        width: 60%;
        .isTitle {
          color: #fcfcfc;
          font-size: 48px;
          line-height: 1.1;
          letter-spacing: -1px;
          margin-bottom: 20px;
        }
        .isDesc {
          color: #fefefe;
          font-size: 16px;
          margin-bottom: 25px;
          p {
            margin-bottom: 20px;
            span {
              font-style: italic;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        .menu-btns {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .menu-btn {
            border: 1px solid #fff;
            border-radius: 50px;
            margin-right: 10px;
            padding: 11px 25px;
            color: #fff;
            font-size: 11px;
            background-color: transparent;
            a {
              color: #fff;
            }
            &:hover {
              background-color: rgba(0, 162, 175, 1);
              border: 1px solid rgba(0, 162, 175, 1);
              cursor: pointer;
            }
          }
        }
        @media only screen and (max-width: 1200px) {
          padding: 0px 15px;
          width: 80%;
        }
        @media only screen and (max-width: 1024px) {
          .isDesc {
            margin-bottom: 0px;
          }
          .menu-btn {
            margin-top: 70px;
          }
        }
        @media only screen and (max-width: 880px) {
          width: 90%;
        }
        @media only screen and (max-width: 768px) {
          max-width: 440px;
          margin: 0 auto;
          padding: 0px 15px;
          .isTitle {
            font-size: 30px;
          }
          .isDesc {
            margin-bottom: 40px;
          }
          .menu-btns {
            margin-top: 0px;
            display: none;
          }
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      height: auto;
      min-height: unset;
      padding-top: 209px;
      padding-bottom: 120px;
    }
    @media only screen and (max-width: 768px) {
      padding-top: 270px;
      padding-bottom: 40px;
    }
  }
  .content {
    .responsible-game-block,
    .social-responsibility-block,
    .collaborations-block {
      padding-top: 80px;
      padding-bottom: 80px;
      text-align: center;
      background-color: transparent;
      scroll-margin-top: 36px;
      .title {
        color: #303132;
        line-height: 1.1;
        margin-bottom: 20px;
      }
      .subtitle {
        color: #878787;
        font-size: 18px;
        line-height: 1.7;
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 50px;
      }
      @media only screen and (max-width: 1024px) {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      @media only screen and (max-width: 768px) {
        padding-top: 50px;
        padding-bottom: 50px;
        max-width: 440px;
        margin: 0 auto;

        .title {
          font-size: 30px;
        }
      }
      @media only screen and (max-width: 500px) {
        scroll-margin-top: 16px;
      }
    }
    .responsible-game-block {
      .title {
        font-size: 30px;
      }
      .boxes {
        display: flex;
        justify-content: center;
        align-items: stretch;
        .box {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          border: 1px solid #dddddd;
          padding: 30px 25px;
          text-align: left;
          font-size: 16px;
          color: #878787;
          .box-image {
            width: 150px;
            height: 150px;
            align-self: center;
            margin-bottom: 30px;
            img {
              max-width: 100%;
              height: auto;
            }
          }
          .box-title {
            margin-bottom: 20px;
          }
          .box-desc {
            margin-bottom: 30px;
          }
          .box-link {
            display: inline-block;
            font-size: 13px;
            margin-top: auto;
            background-color: transparent;
            border: 1px solid rgba(0, 162, 175, 1);
            color: rgba(0, 162, 175, 1);
            border-radius: 50px;
            padding: 15px 30px;
            &:hover {
              background-color: rgba(0, 162, 175, 1);
              color: #fff;
            }
          }
          &.box-2 {
            border-left: 0 none;
            border-right: 0 none;
          }
        }
      }
      .footer {
        margin-top: 50px;

        .footer-text {
          color: #878787;
          font-size: 16px;
          margin-bottom: 20px;
          font-style: italic;
        }
        .footer-link {
          display: inline-block;
          font-size: 13px;
          background-color: transparent;
          border: 1px solid rgba(0, 162, 175, 1);
          color: rgba(0, 162, 175, 1);
          border-radius: 50px;
          padding: 15px 30px;
          &:hover {
            background-color: rgba(0, 162, 175, 1);
            color: #fff;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        .title {
          font-size: 30px;
        }
        .boxes {
          flex-direction: column;
          .box {
            &.box-2 {
              border: 1px solid #dddddd;
              border-top: 0 none;
              border-bottom: 0 none;
            }
          }
        }
      }
    }
    .social-responsibility-block {
      background-color: rgba(245, 245, 245, 1);
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100vw;
        background: inherit;
        top: 0;
        left: -50vw;
        right: -50vw;
        margin-right: auto;
        margin-left: auto;
        z-index: -1;
      }
      .title {
        font-size: 48px;
      }
      .home-thumbs-outer {
        width: 100%;
        max-width: unset;
        @media only screen and (max-width: 768px) {
          width: 100%;
          max-width: 600px;
        }
      }
      .home-thumbs-wrapper {
        .swiper-slide {
          width: 232px;
        }
        .home-swiper-btns {
          position: absolute;
          top: 50%;
          width: 40px;
          height: 60px;
          line-height: 60px;
          font-size: 20px;
          margin-top: -35px;
          text-align: center;
          background: rgba(0, 0, 0, 0.2);
          color: #fff;
          z-index: 9;
          transition: all 0.2s;
          .icon {
            font-size: 20px;
          }
          &.swiper-button-prev {
            left: 0px;
          }
          &.swiper-button-next {
            right: 0px;
          }
          &:hover {
            cursor: pointer;
            background: rgba(0, 162, 175, 1);
          }
        }

        .home-swiper-pagination {
          &.swiper-pagination {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
          }
          .swiper-pagination-bullet {
            background-color: #d6d6d6;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            margin: 5px 7px;

            &:hover {
              transform: scale(1.5);
            }
            &.swiper-pagination-bullet-active {
              background-color: rgba(0, 162, 175, 1);
            }
          }
        }
        .home-swiper-thumb {
          display: inline-flex;
          padding: 0;
          cursor: pointer;
          position: relative;
          width: 100%;
          a {
            width: 100%;
            img {
              border-radius: 0px;
              width: 100%;
              display: block;
            }
          }
          .thumb-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity 0.4s ease;
            -webkit-transition: opacity 0.4s ease;
            .thumb-bg {
              width: 100%;
              height: 100%;
              background: rgba(0, 162, 175, 1);
              display: flex;
              align-items: center;
              justify-content: center;
              .thumb-title-wrap {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                height: 100%;
                color: #ffffff;
                .thumb-title {
                  display: inline-block;
                  font-size: 12px;
                  line-height: 1.4;
                  border: 3px solid;
                  padding: 6px 15px;
                  margin-bottom: 10px;
                  width: 80%;
                  height: auto;

                  &.thumb-title-7 {
                    word-break: break-all;
                  }
                  @media only screen and (max-width: 500px) {
                    font-size: 10px;
                    &.thumb-title-0 {
                      word-break: break-all;
                    }
                  }
                }
                .portfolio-item-cats {
                  font-size: 14px;
                  width: 100%;
                  height: auto;
                }
              }
            }
            &:hover {
              opacity: 1;
            }
          }
        }
      }

      .footer {
        margin-top: 50px;

        .footer-text {
          color: #878787;
          font-size: 16px;
          margin-bottom: 20px;
          font-style: italic;
        }
        .footer-link {
          display: inline-block;
          font-size: 13px;
          background-color: transparent;
          border: 1px solid rgba(0, 162, 175, 1);
          color: rgba(0, 162, 175, 1);
          border-radius: 50px;
          padding: 15px 30px;
          &:hover {
            background-color: rgba(0, 162, 175, 1);
            color: #fff;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        .title {
          font-size: 30px;
        }
      }
    }
    .collaborations-block {
      background-color: transparent;
      .title {
        font-size: 48px;
      }
      .subtitle {
        margin-bottom: 30px;
      }
      .logos-images {
        display: flex;
        justify-content: center;
        align-items: stretch;
        margin-bottom: 60px;
        width: 100%;
        max-width: 460px;
        margin-left: auto;
        margin-right: auto;
        .logo-image {
          padding: 20px 20px 0;
          //  width: 50%;
          width: 100%;
          max-width: 230px;
          margin: auto;
          img {
            max-width: 100%;
          }
          .text {
            display: block;
            text-align: center;
            width: 100%;
            font-size: 12px;
            line-height: 1;
            margin-top: 8px;
            font-style: italic;
          }
          @media only screen and (max-width: 480px) {
            width: 100%;
            max-width: 100%;
          }
        }
      }
      .home-collaborations-section-thumbs-wrapper {
        justify-content: center;
      }
      .home-collaborations-section-swiper-prev-btn,
      .home-collaborations-section-swiper-next-btn {
        display: inline-block;
        position: absolute;
        width: 28px;
        height: 28px;
        font-size: 28px;
        color: #ccc;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
        @media only screen and (min-width: 480px) {
          display: none;
        }
      }
      .home-collaborations-section-swiper-prev-btn {
        left: 0;
      }
      .home-collaborations-section-swiper-next-btn {
        right: 0;
      }
      .collaborations-link {
        display: inline-block;
        font-size: 13px;
        background-color: transparent;
        border: 1px solid rgba(0, 162, 175, 1);
        color: rgba(0, 162, 175, 1);
        border-radius: 50px;
        padding: 15px 30px;
        &:hover {
          background-color: rgba(0, 162, 175, 1);
          color: #fff;
        }
      }
      @media only screen and (max-width: 768px) {
        .title {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
