<template>
  <div class="collaborations-component">
    <div class="header" id="collaborationsHeader">
      <div class="header-bg"></div>
      <div class="wrapper">
        <div class="header-text">
          <div class="header-text-inner">
            <h3 class="isTitle font-hl-bold">ჩვენ ვთანამშრომლობთ</h3>
            <div class="isDesc font-hl-roman">
              <p>
                გუნდებთან და სპორტსმენებთან თანამშრომლობით ვცდილობთ, აქტიურად ვიყოთ ჩართული ყოველდღიურობაში. ჩვენ გვიყვარს ჩვენი საქმე და ინდუსტრია,
                რაც იმას ნიშნავს, რომ ჩვენს ამბასადორებს, პარტნიორობის ფარგლებში, მაქსიმალური ყურადღება ეთმობათ.
              </p>
            </div>
            <div class="menu-btns font-hl-roman">
              <div class="menu-btn">
                <router-link :to="{ name: 'collaborations', hash: '#sponsorships' }">დაათვალიერე ჩვენი პარტნიორები</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="wrapper">
        <div class="collaborations-block" id="sponsorships">
          <h2 class="title font-hl-bold">პარტნიორები</h2>
          <p class="subtitle font-hl-light">
            ჩვენ გვიყვარს სპორტი. გუნდებთან და სპორტსმენებთან თანამშრომლობით ვცდილობთ დავუახლოვდეთ სპორტის მოყვარულებს და მხარი დავუჭიროთ წარმატებულ
            სპორტსმენებს.
          </p>
          <div class="logos-images">
            <swiper ref="collaborationsThumbsSwiper" :options="collaborationsThumbsSwiperOptions()" class="collaborations-thumbs-wrapper">
              <swiper-slide>
                <div class="logo-image">
                  <img :src="require('@/assets/collaborations-component/logo2.jpg')" alt="" />
                  <p class="text font-hl-light">ახალგაზრდული მედია კავშირი</p>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="logo-image">
                  <img :src="require('@/assets/collaborations-component/logo1.jpg')" alt="" />
                  <p class="text font-hl-light">საქართველოს ძლიერ მამაკაცთა ფედერაცია</p>
                </div>
              </swiper-slide>
              <div class="collaborations-swiper-prev-btn swiper-button-prev" slot="button-prev">
                <i class="icon icon-arrow_carrot-left"></i>
              </div>
              <div class="collaborations-swiper-next-btn swiper-button-next" slot="button-next">
                <i class="icon icon-arrow_carrot-right"></i>
              </div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
export default {
  name: "CollaborationsComponent",
  props: {},
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      collaborationsSectionObserver: null,
    };
  },
  methods: {
    collaborationsThumbsSwiperOptions() {
      return {
        slidesPerView: 1,
        spaceBetween: 0,
        initialSlide: 0,
        observer: true,
        observeParents: true,
        loop: true,
        autoplay: false,

        navigation: {
          nextEl: ".swiper-button-next.collaborations-swiper-next-btn",
          prevEl: ".swiper-button-prev.collaborations-swiper-prev-btn",
        },
        initialSlide: 0,
        breakpoints: {
          480: {
            slidesPerView: 2,
          },
        },
      };
    },
  },
  mounted() {
    let collaborationsHeaderHeight = document.getElementById(
      "collaborationsHeader"
    ).offsetHeight;

    const collaborationsHeader = this.$scrollmagic.scene({
      triggerElement: "#collaborationsHeader",
      triggerHook: 0.5,
      duration: collaborationsHeaderHeight,
    });
    this.$scrollmagic.addScene(collaborationsHeader);
    collaborationsHeader.on("enter", (event) => {
      this.$router
        .replace({
          name: this.$route.name,
          hash: ``,
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    });
  },
};
</script>

<style lang="scss">
.collaborations-component {
  width: 100%;
  min-height: 100%;
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 552px;
    z-index: 9;
    .header-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/collaborations-component/collaborations-header-bg.jpg");
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center center;
      background-size: cover;
      z-index: -1;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
      }
    }
    .header-text {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      .header-text-inner {
        padding: 0px 25px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        .isTitle {
          color: #fcfcfc;
          font-size: 48px;
          line-height: 1.1;
          letter-spacing: -1px;
          margin-bottom: 20px;
        }
        .isDesc {
          color: #fefefe;
          font-size: 16px;
          margin-bottom: 30px;
          p {
            margin-bottom: 20px;
            span {
              font-style: italic;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        .menu-btns {
          display: flex;
          justify-content: center;
          align-items: center;
          .menu-btn {
            border: 1px solid #fff;
            border-radius: 50px;
            margin-right: 10px;
            padding: 14px 30px;
            font-size: 13px;
            color: #fff;
            background-color: transparent;
            a {
              color: #fff;
            }
            &:hover {
              background-color: rgba(0, 162, 175, 1);
              border: 1px solid rgba(0, 162, 175, 1);
              cursor: pointer;
            }
          }
        }
        @media only screen and (max-width: 1200px) {
          padding: 0px 15px;
          width: 80%;
        }
        @media only screen and (max-width: 1024px) {
          .isDesc {
            margin-bottom: 0px;
          }
          .menu-btn {
            margin-top: 70px;
          }
        }
        @media only screen and (max-width: 880px) {
          width: 90%;
        }
        @media only screen and (max-width: 768px) {
          width: 100%;
          max-width: 440px;
          margin: 0 auto;
          padding: 0px 15px;
          .isTitle {
            font-size: 30px;
          }
          .isDesc {
            display: none;
          }
          .menu-btns {
            margin-top: 0px;
            display: none;
          }
        }
        @media only screen and (max-width: 500px) {
          .isTitle {
            font-size: 26px;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      min-height: 262px;
    }
  }
  .content {
    .collaborations-block {
      padding-top: 80px;
      padding-bottom: 80px;
      text-align: center;
      background-color: transparent;
      scroll-margin-top: 36px;
      .title {
        color: #303132;
        line-height: 1.1;
        margin-bottom: 20px;
        font-size: 48px;
      }
      .subtitle {
        color: #878787;
        font-size: 18px;
        line-height: 1.7;
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
      .logos-images {
        display: flex;
        justify-content: center;
        align-items: stretch;

        width: 100%;
        max-width: 460px;
        margin-left: auto;
        margin-right: auto;
        .logo-image {
          padding: 20px 20px 0;
          //  width: 50%;
          width: 100%;
          max-width: 230px;
          margin: auto;
          img {
            max-width: 100%;
          }
          .text {
            display: block;
            text-align: center;
            width: 100%;
            font-size: 12px;
            line-height: 1;
            margin-top: 8px;
            font-style: italic;
          }
        }
        .home-collaborations-section-thumbs-wrapper {
          justify-content: center;
        }
        .collaborations-swiper-prev-btn,
        .collaborations-swiper-next-btn {
          display: inline-block;
          position: absolute;
          width: 28px;
          height: 28px;
          font-size: 28px;
          color: #ccc;
          top: 50%;
          transform: translateY(-50%);
          z-index: 999;
          @media only screen and (min-width: 480px) {
            display: none;
          }
        }
        .collaborations-swiper-prev-btn {
          left: 0;
        }
        .collaborations-swiper-next-btn {
          right: 0;
        }
      }
      @media only screen and (max-width: 1024px) {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      @media only screen and (max-width: 768px) {
        padding-top: 50px;
        padding-bottom: 50px;
        max-width: 440px;
        margin: 0 auto;
        .title {
          font-size: 30px;
        }
      }
      @media only screen and (max-width: 480px) {
        scroll-margin-top: 16px;
      }
    }
  }
}
</style>
