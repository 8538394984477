import store from "@/store";
import axios from "axios";
import router from "../../router";

export default {
  setLanguage: function (lang) {
    store.dispatch("setLanguageCode", lang);
  },

  calculateLangFromUrl: function () {
    return window.location.pathname.split("/")[3];
  },

  fetchLanguages: function () {
    return axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/content/v1/language/getLanguages`
    );
  },

  setLanguages: function (languages) {
    store.dispatch("setLanguages", languages);
  },

  selectLanguage: function (lang) {
    if (this.calculateLangFromUrl() != lang) {
      router.push({ params: { lang } });
    }
  },
};
