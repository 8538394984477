<template>
  <div class="article-page">
    <!-- <div class="wrapper"> -->
    <!-- <h4>{{this.$route.params.slug}}</h4> -->
    <!-- <h4>{{slug}}</h4> -->
    <div class="articles-box" v-for="article in allarticles" :key="article.id" :items="allarticles" :per-page="perPage" :current-page="currentPage">
      <div class="article" v-if="article.id == currentPage">
        <div class="images-wrap">
          <div class="images-inner">
            <swiper ref="articlesThumbsSwiper" :options="articlesSwiperOptions()" class="articles-thumbs-wrapper" v-if="article.articleImgs && article.articleImgs.length > 1">
              <swiper-slide v-for="(item, index) in article.articleImgs" :key="index">
                <div class="articles-swiper-thumb">
                  <img :src="require(`@/assets/crs-component/${item}`)" />
                </div>
              </swiper-slide>
              <div class="articles-swiper-btns swiper-button-prev" slot="button-prev">
                <!-- <i class="icon icon-arrow_carrot-left"></i> -->
                <span></span>
              </div>
              <div class="articles-swiper-btns swiper-button-next" slot="button-next">
                <!-- <i class="icon icon-arrow_carrot-right"></i> -->
                <span></span>
              </div>
            </swiper>
            <div class="articles-thumb" v-if="article.articleImgs && article.articleImgs.length == 1">
              <img :src="require(`@/assets/crs-component/${article.articleImgs[0]}`)" />
            </div>
          </div>
        </div>
        <div class="text-wrap">
          <div class="text-inner">
            <h1 class="article-title font-hl-bold">{{article.title}}</h1>
            <div class="article-desc font-hl-light" v-html="article.articleDesc"></div>
            <div class="article-desc-img cmr" v-if="article.slug == 'colour-my-run-2019'"><img :src="require('../../assets/crs-component/articles-images/cmr-text-img.png')" alt=""></div>
            <div class="article-desc-img beating-hearts" v-if="article.slug == 'beating-hearts-foundation'"><img :src="require('../../assets/crs-component/articles-images/beatinghearts-text-img.jpg')" alt=""></div>
            <div class="article-desc-img catharsis" v-if="article.slug == 'catharsis-georgia'"><img :src="require('../../assets/crs-component/articles-images/catharsis-text-img.jpg')" alt=""></div>
            <div class="article-desc-img blood" v-if="article.slug == 'blood-drive-georgia'"><img :src="require('../../assets/crs-component/articles-images/blood-drive-text-img.png')" alt=""></div>
            <div class="article-desc-img wings" v-if="article.slug == 'wings-for-life'"><img :src="require('../../assets/crs-component/articles-images/wings-for-life-text-img.png')" alt=""></div>
          </div>

        </div>
        <div class="pagination-outer">
          <h4 class="pagination-article-title font-hl-bold">{{article.title}}</h4>
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" next-class="nextlink" prev-class="prevlink" last-class="lastlink" first-class="firstlink" ellipsis-text="" prev-text="" next-text="" limit=1></b-pagination>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { BPagination } from "bootstrap-vue";
import $ from "jquery";
export default {
  name: "ArticleComponent",
  props: ["slug"],
  components: {
    Swiper,
    SwiperSlide,
    "b-pagination": BPagination,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      onearticle: {},
      perPage: 1,
      currentPage: 1,
    };
  },
  methods: {
    oneArticle() {
      this.allarticles.forEach((art) => {
        if (art.slug == this.slug) {
          this.currentPage = art["id"];
        }
      });
    },
    articlesSwiperOptions() {
      return {
        slidesPerView: 1,
        spaceBetween: 0,
        initialSlide: 0,
        observer: true,
        observeParents: true,
        loop: true,
        // autoplay: false,
        autoplay: {
          delay: 3000,
        },
        navigation: {
          nextEl: ".articles-swiper-btns.swiper-button-next",
          prevEl: ".articles-swiper-btns.swiper-button-prev",
        },
      };
    },
    getArticle() {
      this.allarticles.forEach((art) => {
        if (art.id == this.currentPage) {
          this.$router
            .replace({
              name: "article",
              params: {
                slug: art["slug"],
              },
            })
            .catch((error) => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      allarticles: "allArticles",
    }),

    rows() {
      return this.allarticles.length;
    },
  },
  watch: {},
  mounted() {
    this.oneArticle();
    $(".page-item.prevlink button").html("&#8592;");
    $(".page-item.nextlink button").html("&#8594;");
  },
  updated() {
    $(".page-item.prevlink button").html("&#8592;");
    $(".page-item.nextlink button").html("&#8594;");
    this.getArticle();
  },
};
</script>

<style lang="scss">
.article-page {
  margin-top: 89px;
  padding: 0;
  .article {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    height: calc(100vh - 130px);
    overflow: hidden;
    position: relative;
    .images-wrap {
      width: 60%;
      height: 100%;
      background-color: #eee;
      display: flex;
      align-items: center;
      justify-content: center;
      .images-inner {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .articles-thumbs-wrapper {
          width: 100%;
          height: 100%;
          .swiper-wrapper {
            align-items: center;
          }
          .swiper-slide {
            width: 100%;
            height: 100%;
          }
          .articles-swiper-btns {
            position: absolute;
            top: 50%;
            width: 60px;
            height: 100px;
            line-height: 100px;
            font-size: 20px;
            margin-top: -35px;
            text-align: center;
            background-color: rgba(0, 0, 0, 1);
            color: #fff;
            z-index: 9;
            transition: all 0.2s;
            span {
              position: absolute;
              display: inline-block;
              width: 12px;
              height: 12px;
              background-color: transparent;
              border: 1px solid #fff;
              border-left: 1px solid transparent;
              border-bottom: 1px solid transparent;
              z-index: 999;
              top: 50%;
              left: 50%;
            }
            &.swiper-button-prev {
              left: 0px;
              span {
                transform: translate(-50%, -50%) rotate(-135deg);
              }
            }
            &.swiper-button-next {
              right: 0px;
              span {
                transform: translate(-50%, -50%) rotate(45deg);
              }
            }
            &:hover {
              cursor: pointer;
            }
            @media only screen and (max-width: 768px) {
              display: none;
            }
          }

          .articles-swiper-thumb {
            padding: 0;
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              max-width: 100%;
              width: 100%;
              height: auto;
              display: block;
            }
          }
        }
        .articles-thumb {
          padding: 0;
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100%;
            width: 100%;
            height: auto;
            max-height: 100%;
            object-fit: cover;
            display: block;
          }
        }
      }
    }
    .text-wrap {
      width: 40%;
      padding: 70px 50px;
      .text-inner {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        scrollbar-width: thin;
        &::-webkit-scrollbar-thumb {
          background-color: transparent;
        }
        &::-webkit-scrollbar-track {
          background-color: #cacacb;
        }
        &::scrollbar-track {
          border-radius: 10px;
          background-color: #cacacb;
        }
        &::-webkit-scrollbar {
          width: 5px !important;
        }
      }
    }
    .article-title {
      font-size: 36px;
      color: #303132;
      margin-bottom: 20px;
      word-break: break-word;
      letter-spacing: -1px;
    }
    .article-desc {
      font-size: 16px;
      color: #7a7a7a;
      padding: 40px 0 0;
      word-break: break-word;
    }
    .article-desc-img {
      text-align: center;
      width: 100%;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      &.catharsis {
        max-width: 200px;
      }
      &.blood {
        max-width: 175px;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
    @media only screen and (max-width: 960px) {
      flex-direction: column;
      height: 100%;
      .images-wrap {
        width: 100%;
        height: 75vw;
      }
      .text-wrap {
        width: calc(100% - 40px);
        padding: 20px 20px;
        margin-top: 30px;
        margin-bottom: 50px;
        .text-inner {
          height: auto;
          overflow-y: unset;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .article-title {
        font-size: 35px;
      }
      .article-desc {
        font-size: 16px;
      }
    }
  }
  .pagination-outer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    position: absolute;
    bottom: 5px;
    right: 40px;
    width: 100%;
    z-index: 999;
    @media only screen and (max-width: 960px) {
      position: static;
      text-align: center;
      justify-content: center;
    }
  }
  .pagination-article-title {
    font-size: 14px;
    background-color: transparent;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    color: #2b2b2b;
    &:hover {
      background-color: rgba(235, 73, 73, 0.85);
      color: #fff;
    }
    @media only screen and (max-width: 960px) {
      display: none;
    }
  }
  .pagination {
    display: flex;
    .page-item {
      &.active {
        display: none;
      }
      &.firstlink {
        display: none;
      }
      &.lastlink {
        display: none;
      }
      &.prevlink,
      &.nextlink {
        width: 40px;
        height: 40px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        button.page-link {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          background-color: transparent;
          cursor: pointer;
        }

        &.disabled {
          display: none;
        }
        &:hover {
          background-color: rgba(235, 73, 73, 0.85);
          button {
            color: #fff;
          }
        }
      }
      &.prevlink {
        margin-left: 10px;
        margin-right: 2px;
        z-index: 999;
      }
      &.nextlink {
        margin-left: 2px;
        margin-right: 10px;
      }
    }
  }
}
</style>
