<template>
  <div id="app">
    <div class="main-wrapper" @scroll="pageScrolled($event)">
      <div class="back-to-top-btn" id="back-to-top" @click="backToTop()"><i class="icon icon-arrow_carrot-up"></i></div>
      <NavbarComponent v-bind="navbarObj"></NavbarComponent>
      <router-view v-bind="[homeComponentObj, aboutComponentObj]" />
      <FooterComponent v-bind="footerObj"></FooterComponent>
      <div class="notification-wrapper font-hl-light" :class="{ show: Notification.Enabled }">
        <div class="cookie-text">ვებგვერდი იყენებს cookie ფაილებს მომხმარებელზე ორიენტირებული მომსახურების მისაწოდებლად.</div>
        <div class="cookie-btn" @click="setRespCookie()">ვეთანხმები</div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { mapGetters } from "vuex";
import CookieService from "@/core/services/cookie.service";
import ContentService from "@/core/services/content.service";
import CoreService from "@/core/services/core.service";
import PostMessageService from "@/core/services/post-message.service";
import NavbarComponent from "@/components/navbar/index.vue";
import FooterComponent from "@/components/footer/index.vue";
import moment from "moment";
import $ from "jquery";
export default {
  components: {
    ContentService,
    PostMessageService,
    NavbarComponent,
    FooterComponent,
  },

  created() {
    this.handleEnvLog();
  },
  data() {
    return {
      isDescWidth: false,
      isTabWidth: false,
      isMobWidth: false,
      showMobMenu: false,
      HeaderFixed: false,
      onArticlePage: false,
      Notification: {
        Enabled: true,
      },
    };
  },
  methods: {
    handleEnvLog() {
      if (process.env.VUE_APP_ENV_LOG === "true") {
        console.log("============= Env =============");
        console.log(process.env);
        const sessionToken = this.$route.query?.sessionToken;
        if (sessionToken) {
          localStorage.setItem("sessionToken", sessionToken);
        } else {
          localStorage.removeItem("sessionToken");
        }
      }
    },
    inViewport($el) {
      var elH = $el.outerHeight(),
        H = $(window).height(),
        r = $el[0].getBoundingClientRect(),
        t = r.top,
        b = r.bottom;
      return Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H));
    },
    pageScrolled(event) {
      // this.$root.$emit("bv::hide::popover");
      this.HeaderFixed = event.target.scrollTop > 70;
      if (event.target.scrollTop > 400) {
        $(".back-to-top-btn").addClass("active");
      } else {
        $(".back-to-top-btn").removeClass("active");
      }
      //FadeIn report item

      if (this.$route.name == "report") {
        let report1 = this.inViewport($(".report1"));
        if (report1 > 100) {
          $(".report1").addClass("fade-in");
        }
        let report2 = this.inViewport($(".report2"));
        if (report2 > 150) {
          $(".report2").addClass("fade-in");
        }
        let report3 = this.inViewport($(".report3"));
        if (report3 > 100) {
          $(".report3").addClass("fade-in");
        }
        let report4 = this.inViewport($(".report4"));
        if (report4 > 150) {
          $(".report4").addClass("fade-in");
        }
        let report5 = this.inViewport($(".report5"));
        if (report5 > 100) {
          $(".report5").addClass("fade-in");
        }
        let report6 = this.inViewport($(".report6"));
        if (report6 > 150) {
          $(".report6").addClass("fade-in");
        }
        let report7 = this.inViewport($(".report7"));
        if (report7 > 100) {
          $(".report7").addClass("fade-in");
        }
        let report8 = this.inViewport($(".report8"));
        if (report8 > 150) {
          $(".report8").addClass("fade-in");
        }
        let report9 = this.inViewport($(".report9"));
        if (report9 > 100) {
          $(".report9").addClass("fade-in");
        }
        let report10 = this.inViewport($(".report10"));
        if (report10 > 150) {
          $(".report10").addClass("fade-in");
        }
        let report11 = this.inViewport($(".report11"));
        if (report11 > 100) {
          $(".report11").addClass("fade-in");
        }
        let report12 = this.inViewport($(".report12"));
        if (report12 > 150) {
          $(".report12").addClass("fade-in");
        }
        let report13 = this.inViewport($(".report13"));
        if (report13 > 100) {
          $(".report13").addClass("fade-in");
        }
        let report14 = this.inViewport($(".report14"));
        if (report14 > 150) {
          $(".report14").addClass("fade-in");
        }
        let report15 = this.inViewport($(".report15"));
        if (report15 > 100) {
          $(".report15").addClass("fade-in");
        }
        let report16 = this.inViewport($(".report16"));
        if (report16 > 150) {
          $(".report16").addClass("fade-in");
        }
        let report17 = this.inViewport($(".report17"));
        if (report17 > 100) {
          $(".report17").addClass("fade-in");
        }
      }
    },
    toggleMenu() {
      // this.showMobMenu = !this.showMobMenu;
      this.showMobMenu = this.showMobMenu ? false : true;
    },

    checkWindowWidth() {
      var width = document.body.clientWidth;
      if (width >= 1200) {
        this.isDescWidth = true;
        this.showMobMenu = false;
      } else {
        this.isDescWidth = false;
      }
      if (width >= 768 && width <= 1200) {
        this.isTabWidth = true;
      } else {
        this.isTabWidth = false;
      }
      if (width <= 768) {
        this.isMobWidth = true;
      } else {
        this.isMobWidth = false;
      }
    },
    backToTop() {
      var VueScrollTo = require("vue-scrollto");
      var options = {
        container: ".main-wrapper",
        easing: "ease-in",
        lazy: false,
        offset: 0,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      var cancelScroll1 = VueScrollTo.scrollTo("#app", 100, options);
    },
    backToTopNav() {
      this.showMobMenu = false;
      this.backToTop();
    },
    setCookieProxy(cname, cvalue, exdays) {
      return CookieService.setCookie(cname, cvalue, exdays);
    },
    setRespCookie() {
      this.setCookieProxy("cookie_notice_accepted", "true", this.exdays);
      setTimeout(() => {
        this.showCookieNotification();
      }, 500);
    },
    showCookieNotification() {
      console.log("CookieNotification");
      let cookie = CookieService.getCookie("cookie_notice_accepted");

      if (cookie === "true") {
        this.Notification.Enabled = false;
      } else {
        this.Notification.Enabled = true;
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        to.hash.length > 0 ? null : this.backToTop();
      }
      // console.log("to.name", to.name);
      if (to.name == "article") {
        this.onArticlePage = true;
      } else {
        this.onArticlePage = false;
      }

      this.showMobMenu = false;
    },
  },
  computed: {
    ...mapGetters([
      "UserPinCode",
      "UserGuid",
      "Username",
      "UserDataIsFetching",
      "CurrentLanguageCode",
      "AllContent",
    ]),
    isMobile() {
      return CoreService.isMobile();
    },
    exdays() {
      let expiryDate = new Date();
      const month = (expiryDate.getMonth() + 1) % 12;
      expiryDate.setMonth(month);
      return expiryDate;
    },
    navbarObj() {
      return {
        HeaderFixed: this.HeaderFixed,
        showMobMenu: this.showMobMenu,
        toggleMenu: this.toggleMenu,
        onArticlePage: this.onArticlePage,
        backToTop: this.backToTop,
        backToTopNav: this.backToTopNav,
      };
    },

    homeComponentObj() {
      return {
        isDescWidth: this.isDescWidth,
        isTabWidth: this.isTabWidth,
        isMobWidth: this.isMobWidth,
      };
    },
    aboutComponentObj() {
      return {
        isDescWidth: this.isDescWidth,
        isTabWidth: this.isTabWidth,
        isMobWidth: this.isMobWidth,
      };
    },
    footerObj() {
      return {
        backToTop: this.backToTop,
      };
    },
  },
  created() {
    this.handleEnvLog();
    if (this.$route.params.slug) {
      this.onArticlePage = true;
    } else {
      this.onArticlePage = false;
    }
  },
  beforeMount() {
    window.addEventListener("resize", this.checkWindowWidth);
    this.checkWindowWidth();
    this.showCookieNotification();
  },
  mounted() {
    console.log("Loading Done");
    PostMessageService.LoadingFinished();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";
// reset styles
body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img,
abbr {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ul li {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
legend {
  color: #000;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
img {
  max-width: 100%;
  height: auto;
}

// main styles

html {
  background-color: #fff;
  height: 100%;
}
body {
  width: 100%;
  // max-width: 1920px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  font-family: "Helvetica-Roman";
  font-size: 16px;
  line-height: 1.2;
  background-color: #fff;
  color: #878787;
}
#app {
  height: 100%;
}

.wrapper {
  width: 100%;
  max-width: 1310px;
  min-width: 320px;
  margin: 0 auto;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  @media only screen and (max-width: 1380px) {
    width: 95%;
  }
  @media only screen and (max-width: 1200px) {
    width: 90%;
  }
  @media only screen and (max-width: 500px) {
    width: 85%;
  }
}
.main-wrapper {
  width: 100%;
  overflow: hidden;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  position: relative;
  height: calc(100vh - 0px);
  top: 0;
  overflow-y: scroll;
  scrollbar-width: thin;
  // scroll-behavior: smooth;
  flex-direction: column;
  // &::-webkit-scrollbar-thumb {
  //   background-color: rgba(255, 255, 255, 0.1);
  // }
  // &::-webkit-scrollbar-track {
  //   background-color: rgba(255, 255, 255, 0.1);
  // }
  // &::scrollbar-track {
  //   border-radius: 10px;
  //   background-color: rgba(255, 255, 255, 0.1);
  // }
  &::-webkit-scrollbar {
    //width: 5px !important;
  }
}
.notification-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  color: #fff;
  font-size: 15px;
  width: 100%;
  z-index: 99999;
  padding: 12px;
  transition: all 0.4s;
  transform: scaleY(0);
  transform-origin: bottom;
  text-align: center;
  background-color: #262729;
  .cookie-text {
    font-size: 13px;
  }
  .cookie-btn {
    cursor: pointer;
    background: linear-gradient(to bottom, #ffffff, #f3f3f3) repeat scroll 0 0
      #f3f3f3;
    border-color: #999;
    color: #222;
    font-size: 12px;
    padding: 2px 12px;
    margin-left: 15px;
    border-radius: 3px;
    line-height: 18px;
  }
  &.show {
    transform: scaleY(1);
  }
}
#back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: block;
  font-size: 17px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: rgba(0, 162, 175, 1) !important;
  color: #fff;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  .icon {
    font-size: 17px;
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
.light-blue {
  color: rgba(0, 162, 175, 1);
}
.mb-20 {
  margin-bottom: 20px;
}
.text-italic {
  font-style: italic;
}
.cursor-pointer {
  cursor: pointer;
}
.no-underline {
  text-decoration: none;
}
</style>
