import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";
import AboutComponent from "@/views/about/index.vue";
import ResponsibleGamingComponent from "@/views/responsible-gaming/index.vue";
import CsrComponent from "@/views/csr/index.vue";
import CollaborationsComponent from "@/views/collaborations/index.vue";
import ReportComponent from "@/views/report/index.vue";
import ArticleComponent from "@/components/article/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "main",
    component: Home,
  },
  {
    path: "/about-betsson",
    name: "about",
    component: AboutComponent,
  },
  {
    path: "/responsible-gaming",
    name: "responsibleGaming",
    component: ResponsibleGamingComponent,
  },

  {
    path: "/csr",
    name: "csr",
    component: CsrComponent,
  },
  {
    path: "/csr/:slug",
    name: "article",
    component: ArticleComponent,
    props: true,
  },
  {
    path: "/collaborations",
    name: "collaborations",
    component: CollaborationsComponent,
  },
  {
    path: "/report",
    name: "report",
    component: ReportComponent,
  },
  {
    path: '/*',
    redirect: { name: "main" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      //  const el = window.location.href.split('#')[1]
      const el = to.hash.substring(1);
      if (el.length) {
        document.getElementById(el)?.scrollIntoView({ behaviour: "smooth" })
      }
    } else if (SavedPosition) {
      return SavedPosition
    }
    else {
      // console.log(document.querySelector('.main-wrapper')?.scrollIntoView());
      document.getElementById('app')?.scrollIntoView({ behaviour: "smooth" })
    }

  }
});

export default router;
