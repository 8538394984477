<template>
  <div class="fixed-scrolling-menu">
    <div class="fixed-scrolling-menu-inner">
      <div class="fixed-scrolling-menu-links">
        <div class="fixed-scrolling-menu-link" v-for="item in fixedMenuItems" :key="item.id">
          <router-link :to="{ name: item.name, hash: item.hash }" :id="'link-'+item.id">
            <span>{{ item.alias }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FixedScrollingMenuComponent",
  props: {
    fixedMenuItems: [],
  },
};
</script>

<style lang="scss">
.fixed-scrolling-menu {
  display: block;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 32px;
  z-index: 100;
  transform: translateZ(0);

  .fixed-scrolling-menu-inner {
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 100%;
    .fixed-scrolling-menu-links {
      .fixed-scrolling-menu-link {
        a {
          width: 8px;
          height: 8px;
          background-color: transparent;
          border-radius: 100%;
          display: block;
          margin: 5px 0px !important;
          border: 1px solid #999;
          position: relative;
          cursor: pointer;
          &.router-link-active {
            border: 1px solid rgba(0, 162, 175, 1);
            background-color: #d6d6d6;
          }
          span {
            display: block;
            position: absolute;
            right: 50px;
            top: 0px;
            background: #000;
            padding: 4px 20px;
            margin-top: -13px;
            color: #fff;
            white-space: nowrap;
            opacity: 0;
            visibility: hidden;
            transition: all 0.5s ease;
            &:after {
              border-top: 17px solid transparent;
              border-left: 20px solid black;
              border-bottom: 17px solid transparent;
              display: block;
              content: "";
              position: absolute;
              right: -20px;
              top: -4px;
            }
          }
        }
        &:hover {
          a {
            border: 1px solid rgba(0, 162, 175, 1);
            background-color: #d6d6d6;
            span {
              opacity: 1;
              visibility: visible;
              right: 30px;
            }
          }
        }
      }
    }
  }
}
</style>
