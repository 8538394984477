import Vue from "vue";
import Vuex from "vuex";

import UserStore from "./user";
import LanguageStore from "./language";
import ContentStore from "./content";
import Articles from "./articles";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    UserStore,
    LanguageStore,
    ContentStore,
    Articles,
  },
});
