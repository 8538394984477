<template>
  <div class="responsible-gaming-component">
    <FixedScrollingMenuComponent :fixedMenuItems="rgFixedMenuItems"></FixedScrollingMenuComponent>
    <div class="header" id="rgHeader">
      <div class="header-bg"></div>
      <div class="wrapper">
        <div class="header-text">
          <div class="header-text-inner">
            <h3 class="isTitle font-hl-bold">ითამაშე მხოლოდ სიამოვნებისთვის</h3>
            <div class="isDesc font-hl-roman">
              <p>ჩვენ გვსურს, რომ ჩვენთან მხოლოდ სიამოვნებისთვის ითამაშონ და სწორედ ამიტომ ვუწევთ პასუხისმგებლიან თამაშს პოპულარიზაციას.</p>
            </div>

            <div class="menu-btns font-hl-roman">
              <div class="menu-btn">
                <router-link :to="{ name: 'responsibleGaming', hash: '#gamingtools' }">პასუხისმგებლიანი თამაშის ხელსაწყოები</router-link>
              </div>
              <div class="menu-btn">
                <router-link :to="{ name: 'responsibleGaming', hash: '#gamingtips' }">რჩევები</router-link>
              </div>
            </div>

            <div class="menu-btn diff">
              <router-link :to="{ name: 'responsibleGaming', hash: '#selfassesmenttest' }">პრობლემური თამაშის ტესტი</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="wrapper">
        <div class="rg-betsson-block" id="betsson">
          <h3 class="title font-hl-bold">პასუხისმგებლიანი თამაში ევროპაბეთზე</h3>
          <p class="subtitle font-hl-light">
            პასუხისმგებლიანი თამაში ჩვენი საქმის ამომავალი წერტილია და მას განსაკუთრებულ ყურადღებას ვუთმობთ. ვიცით, რომ აზარტულ თამაშებს თან რისკი
            სდევს. მოთამაშეების უმრავლესობას თამაში ცხოვრებაში მრავალფეროვნებასა და გასართობს სთავაზობს, თუმცა ზოგისთვის, შესაძლოა, პრობლემატური
            გახდეს.
          </p>
          <div class="video-container">
            <div class="playerHere">
              <vue-plyr class="vid-player">
                <div data-plyr-provider="vimeo" data-plyr-embed-id="378224175"></div>
              </vue-plyr>
            </div>
          </div>

          <div class="boxes">
            <div class="box box-1">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/realitycheck-top-box-1-img.svg')" alt="" />
              </div>
              <h4 class="box-title font-hl-bold">ჩვენი პასუხისმგებლობა</h4>
              <p class="box-desc font-hl-light mb-20">
                აზარტული ონლაინ თამაშების პლატფორმის შექმნას თან დიდი პასუხისმგებლობა მოყვება. ჩვენ ვზრუნავთ, რომ მოთამაშეებს უსაფრთხო, დაცული და
                სასიამოვნო თამაშები შევთავაზოთ. ჩვენ გვაქვს გარკვეული სერვისები და მათი დახმარებით მოთამაშეებს პასუხისმგებლობით თამაშში ვეხმარებით.
                ბეთსონის მასშტაბით, ონლაინ ბიზნესში პასუხისმგებლიანი თამაშის პირველი ტრეინინგი 2002 წელს გაიმართა, ხოლო პასუხისმგებლობით თამაშის
                სპეციალისტი პირველად 2007 წელს ავიყვანეთ.
              </p>
              <p class="box-desc font-hl-light">
                ჩვენს ვებგვერდსა და პასუხისმგებლიანი თამაშის მეთოდებს, ყოველთვის ამოწმებს, აფასებს და რეკომენდაციებს უწევს The Global Gambling
                Guidance Group. ტრეინინგების ფარგლებში მოწმდება რამდენად მზად ვართ და შეგვიძლია აზარტულ თამაშებთან თანმდევ პრობლემებთან ბრძოლა და
                გამკლავება.
              </p>
            </div>
            <div class="box box-2">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/realitycheck-top-box-2-img.svg')" alt="" />
              </div>
              <h4 class="box-title font-hl-bold">პასუხისმგებლობით თამაშის პოპულარიზაცია</h4>
              <p class="box-desc font-hl-light mb-20">
                რეგულარულად ვუწევთ პასუხისმგებლობიან თამაშს პოპულარიზაციას. მოთამაშეებს ვთავაზობთ სხვადასხვა ხელსაწყოებს, რომლებიც მათ საშუალებას
                აძლევს აკონტროლონ თამაში და საკუთარი თავი.
              </p>
              <p class="box-desc font-hl-light">
                მოთამაშეების კეთილდღეობა ჩვენთვის მთავარია, ამიტომ როგორც ჩვენ – ევროპაბეთი, ასევე მთელი ბეთსონ ჯგუფი, მსოფლიოს პასუხისმგებლობით
                თამაშის კონფერენციებზე საპატიო სტუმრები ვართ ხოლმე.
              </p>
            </div>
            <div class="box box-3">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/realitycheck-bottom-box-1-img.svg')" alt="" />
              </div>
              <h4 class="box-title font-hl-bold">ოჯახის წევრებსა და მეგობრებზე ზრუნვა</h4>
              <p class="box-desc font-hl-light">
                მოთამაშეთა პირადი ინფორმაციის დაცვის მიზნით, მესამე პირს მათ შესახებ ინფორმაციას ვერ გადავცემთ, თუმცა შეგვიძლია ისეთი ორგანიზაციების
                საკონტაქტო ინფორმაცია მივაწოდოთ, რომლებიც აზარტულ თამაშებთან დაკავშირებული პრობლემების მოგვარებაში დაეხმარებათ.
              </p>
            </div>
            <div class="box box-4">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/realitycheck-bottom-box-2-img.svg')" alt="" />
              </div>
              <h4 class="box-title font-hl-bold">თანამშრომლების ტრეინინგი</h4>
              <p class="box-desc font-hl-light">
                ყველა ახალი თანამშრომელი, განურჩევლად როლისა და მოვალეობისა, გაივლის გადამზადების კურსს, რომელიც პასუხისმგებლიან თამაშსა და სოციალურ
                პასუხისმგებლობას ეხება. ტრეინინგები წელიწადში რამდენჯერმე ჩვენს ოფისში ტარდება.
              </p>
            </div>
            <div class="box box-5">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/realitycheck-bottom-box-3-img.svg')" alt="" />
              </div>
              <h4 class="box-title font-hl-bold">მუდამ თქვენთან</h4>
              <p class="box-desc font-hl-light">
                ჩვენი ქოლ ცენტრი 24 საათის განმავლობაში მუშაობს. ქოლ ცენტრის ყველა თანამშრომელს გავლილი აქვს პასუხისმგებლობით თამაშის ტრეინინგი და
                ისინი მზად არიან ნებისმიერ დროს დაგეხმარონ.
              </p>
            </div>
          </div>
        </div>

        <div class="reality-check-block" id="realitycheck">
          <h3 class="title font-hl-bold">დროის კონტროლი</h3>
          <p class="subtitle font-hl-bold">აკონტროლე თამაშის დრო და ითამაშე მხოლოდ სიამოვნებისთვის</p>
          <div class="video-container">
            <p class="font-hl-light video-desc">
              მოთამაშეების კეთილდღეობისთვის ევროპაბეთმა შექმნა ხელსაწყო – დროის კონტროლი. მოთამაშეებს, რომლებსაც უფიქსირდებათ სათამაშო სესიის
              საშუალოზე მეტი ხანგრძლივობა, ვატყობინებთ, რომ საიტზე ნორმაზე მეტ დროს ატარებენ. მიღებული ინფრომაცია, მოთამაშეს საშუალებას აძლევს აღიქვას
              რეალური სურათი და თავად გადაწყვიტოს, სურს თუ არა თამაშის გაგრძელება.
            </p>
            <div class="playerHere">
              <vue-plyr class="vid-player">
                <div data-plyr-provider="vimeo" data-plyr-embed-id="392656125"></div>
              </vue-plyr>
            </div>
          </div>
        </div>

        <div class="self-assesment-test-block" id="selfassesmenttest">
          <h3 class="title font-hl-bold">პრობლემური თამაშის ტესტი</h3>
          <p class="subtitle font-hl-light">
            თუ თვლი, რომ შესაძლოა აზარტულ თამაშებზე დამოკიდებულება გაგივითარდეს, გააკეთე ჩვენი ტესტი და შეაფასე რისკები
          </p>
          <QuizComponent></QuizComponent>
        </div>

        <div class="gaming-tools-block" id="gamingtools">
          <h3 class="title font-hl-bold">პასუხისმგებლიანი თამაშის ხელსაწყოები</h3>
          <p class="subtitle font-hl-light">ჩვენ მოთამაშეებს პასუხისმგებლიანი თამაშის სხვადასხვა საშუალებას ვთავაზობთ.</p>
          <div class="boxes">
            <div class="box box-1">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/gamingtools-box-1-img.svg')" alt="" />
              </div>
              <h4 class="box-title font-hl-bold">პრობლემური თამაშის ტესტი</h4>
              <p class="box-desc font-hl-light">
                ტესტი შედგენილია ფსიქოლოგების მიერ და რაც მთავარია, ანონიმურია. ეს ტესტი მოთამაშეს საშუალებას აძლევს თამაშის სპეციფიკა შეისწავლოს.
                ტესტის დასრულების შემდეგ კი შეუძლია აირჩიოს თამაშისგან ნებაყოფლობით დასვენება.
              </p>
            </div>
            <div class="box box-2">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/gamingtools-box-2-img.svg')" alt="" />
              </div>
              <h4 class="box-title font-hl-bold">ასაკის გადამოწმება</h4>
              <p class="box-desc font-hl-light">
                ასაკის კონტროლის პროცედურები გარანტიას გვაძლევს, რომ ჩვენს საიტებზე არასრულწლოვანი მოზარდები ვერ ითამაშებენ.
              </p>
            </div>
            <div class="box box-3">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/gamingtools-box-3-img.svg')" alt="" />
              </div>
              <h4 class="box-title font-hl-bold">თამაშის ისტორია</h4>
              <p class="box-desc font-hl-light">
                თამაშის დეტალური ისტორია მოთამაშეს საშუალებას აძლევს თვალი ადევნოს საიტზე მისი აქტივობების ისტორიას, რაც, რა თქმა უნდა, თამაშის უკეთ
                გაკონტროლებაში ეხმარება.
              </p>
            </div>
            <div class="box box-4">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/gamingtools-box-4-img.svg')" alt="" />
              </div>
              <h4 class="box-title font-hl-bold">დროის კონტროლი</h4>
              <p class="box-desc font-hl-light">
                სათამაშო სესიის განმავლობაში, გარკვეული დროის შემდეგ, ეკრანზე გამოჩნდება შეტყობინება, რომელიც დეტალურ ინფორმაციას მოგაწვდის თამაშში
                გატარებული დროის შესახებ.
              </p>
            </div>
          </div>
        </div>

        <div class="gaming-tips-block" id="gamingtips">
          <h3 class="title font-hl-bold">რჩევები თამაშის დროს</h3>
          <p class="subtitle font-hl-light">
            იმისათვის, რომ აზარტული თამაშები გასართობი და უსაფრთხო იყოს, საინტერესო ინფორმაცია და რჩევები შეგიგროვეთ. თამაშის პროცესის უკეთ გაცნობითა
            და შესწავლით რისკები მაქსიმალურად შეამცირდება.
          </p>

          <div class="boxes top">
            <div class="box box-1">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/gamingtips-top-box-1-img.svg')" alt="" />
              </div>
              <p class="box-desc font-hl-light">აზარტული თამაშები შემოსავლის წყარო არ არის. მიუდექი როგორც ჰობის, გართობის საშუალებას.</p>
            </div>
            <div class="box box-2">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/gamingtips-top-box-2-img.svg')" alt="" />
              </div>
              <p class="box-desc font-hl-light">ითამაშე მხოლოდ ხელმისაწვდომ თანხებზე.</p>
            </div>
            <div class="box box-3">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/gamingtips-top-box-3-img.svg')" alt="" />
              </div>
              <p class="box-desc font-hl-light">წაგებული თანხის ამოღებას ნუ ეცდები. ითამაშე მხოლოდ სიამოვნებისთვის.</p>
            </div>
            <div class="box box-4">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/gamingtips-top-box-4-img.svg')" alt="" />
              </div>
              <p class="box-desc font-hl-light">დააყენე ლიმიტი.</p>
            </div>
          </div>
          <div class="boxes bottom">
            <div class="box box-1">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/gamingtips-bottom-box-1-img.svg')" alt="" />
              </div>
              <p class="box-desc font-hl-light">შეზღუდე სათამაშო დრო.</p>
            </div>
            <div class="box box-2">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/gamingtips-bottom-box-2-img.svg')" alt="" />
              </div>
              <p class="box-desc font-hl-light">არ ითამაშო, როცა ცუდ ხასიათზე ან დეპრესიაში ხარ.</p>
            </div>
            <div class="box box-3">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/gamingtips-bottom-box-3-img.svg')" alt="" />
              </div>
              <p class="box-desc font-hl-light">აზარტული თამაშები და ალკოჰოლი ცუდი კომბინაციაა.</p>
            </div>
            <div class="box box-4">
              <div class="box-image">
                <img :src="require('@/assets/responsible-gaming-component/gamingtips-bottom-box-4-img.svg')" alt="" />
              </div>
              <p class="box-desc font-hl-light">დააბალანსე აზარტული თამაშები სხვა აქტივობებით.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FixedScrollingMenuComponent from "@/components/fixed-scrolling-menu/index.vue";
import QuizComponent from "@/components/quiz/index.vue";
import router from "@/router";

export default {
  name: "ResponsibleGamingComponent",
  props: {},
  components: {
    FixedScrollingMenuComponent,
    QuizComponent,
  },
  data() {
    return {
      rgLongSectionObserver: null,
      rgShortSectionObserver: null,
    };
  },
  methods: {},
  computed: {
    rgFixedMenuItems() {
      return [
        {
          id: 1,
          name: "responsibleGaming",
          hash: "#betsson",
          alias: "RG AT BETSSON",
        },
        {
          id: 2,
          name: "responsibleGaming",
          hash: "#realitycheck",
          alias: "RealityCheck",
        },
        {
          id: 3,
          name: "responsibleGaming",
          hash: "#selfassesmenttest",
          alias: "Self Assesment Test",
        },
        {
          id: 4,
          name: "responsibleGaming",
          hash: "#gamingtools",
          alias: "Gaming Tools",
        },
        {
          id: 5,
          name: "responsibleGaming",
          hash: "#gamingtips",
          alias: "Gaming Tips",
        },
      ];
    },
  },
  mounted() {
    let rgHeaderHeight = document.getElementById("rgHeader").offsetHeight;
    let betssonHeight = document.getElementById("betsson").offsetHeight;
    let realitycheckHeight =
      document.getElementById("realitycheck").offsetHeight;
    let selfassesmenttestHeight =
      document.getElementById("selfassesmenttest").offsetHeight;
    let gamingtoolsHeight = document.getElementById("gamingtools").offsetHeight;
    let gamingtipsHeight = document.getElementById("gamingtips").offsetHeight;

    const rgHeader = this.$scrollmagic.scene({
      triggerElement: "#rgHeader",
      triggerHook: 0.5,
      duration: rgHeaderHeight,
    });
    this.$scrollmagic.addScene(rgHeader);
    rgHeader.on("enter", (event) => {
      this.$router
        .replace({
          name: this.$route.name,
          hash: ``,
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    });
    const betsson = this.$scrollmagic
      .scene({
        triggerElement: "#betsson",
        triggerHook: 0.5,
        duration: betssonHeight,
      })
      .setClassToggle("#link-1", "router-link-active");
    this.$scrollmagic.addScene(betsson);

    const realitycheck = this.$scrollmagic
      .scene({
        triggerElement: "#realitycheck",
        triggerHook: 0.5,
        duration: realitycheckHeight,
      })
      .setClassToggle("#link-2", "router-link-active");
    this.$scrollmagic.addScene(realitycheck);

    const selfassesmenttest = this.$scrollmagic
      .scene({
        triggerElement: "#selfassesmenttest",
        triggerHook: 0.5,
        duration: selfassesmenttestHeight,
      })
      .setClassToggle("#link-3", "router-link-active");
    this.$scrollmagic.addScene(selfassesmenttest);

    const gamingtools = this.$scrollmagic
      .scene({
        triggerElement: "#gamingtools",
        triggerHook: 0.5,
        duration: gamingtoolsHeight,
      })
      .setClassToggle("#link-4", "router-link-active");
    this.$scrollmagic.addScene(gamingtools);

    const gamingtips = this.$scrollmagic
      .scene({
        triggerElement: "#gamingtips",
        triggerHook: 0.5,
        duration: gamingtipsHeight,
      })
      .setClassToggle("#link-5", "router-link-active");
    this.$scrollmagic.addScene(gamingtips);
  },
};
</script>
<style lang="scss">
.responsible-gaming-component {
  width: 100%;
  min-height: 100%;

  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 599px;
    z-index: 9;
    .header-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/responsible-gaming-component/responsible-gaming-header-bg.jpg");
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center center;
      background-size: cover;
      z-index: -1;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    .header-text {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      .header-text-inner {
        padding: 0px 25px;
        // width: 60%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        .isTitle {
          color: #fcfcfc;
          font-size: 48px;
          line-height: 1.1;
          letter-spacing: -1px;
          margin-bottom: 20px;
        }
        .isDesc {
          color: #fefefe;
          font-size: 16px;
          margin-bottom: 25px;
          width: 60%;
          margin-left: auto;
          margin-right: auto;
          p {
            margin-bottom: 20px;
            span {
              font-style: italic;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        .menu-btns {
          display: flex;
          justify-content: center;
          align-items: center;
          .menu-btn {
            border: 1px solid #fff;
            border-radius: 50px;
            margin-right: 10px;
            padding: 14px 30px;
            font-size: 13px;
            color: #fff;
            background-color: transparent;
            a {
              color: #fff;
            }
            &:hover {
              background-color: rgba(0, 162, 175, 1);
              border: 1px solid rgba(0, 162, 175, 1);
              cursor: pointer;
            }
          }
        }
        .menu-btn {
          display: inline-flex;
          align-items: center;
          margin-top: 20px;
          &.diff {
            overflow: hidden;
            width: auto;
            color: #fff;
            font-size: 13px;

            a {
              display: inline-flex;
              align-items: center;
              transform: translateX(-12px);
              transition: all 0.35s linear;
              transform-origin: left;
              color: #fff;

              &::before {
                content: "";
                display: inline-block;
                width: 36px;
                height: 2px;
                background-color: #fff;
                margin-right: 18px;
              }
            }
            &:hover {
              a {
                transform: translateX(0px);
                color: rgba(0, 162, 175, 1);
                &::before {
                  background-color: rgba(0, 162, 175, 1);
                }
              }
              cursor: pointer;
            }
          }
        }
        @media only screen and (max-width: 1200px) {
          padding: 0px 15px;
          .isDesc {
            width: 80%;
          }
        }
        @media only screen and (max-width: 880px) {
          .isDesc {
            width: 90%;
          }
        }
        @media only screen and (max-width: 768px) {
          padding: 0px 15px;
          width: 100%;
          max-width: 440px;
          margin: 0 auto;
          .isTitle {
            font-size: 30px;
          }
          .isDesc {
            display: none;
            width: 100%;
          }
          .menu-btns {
            display: none;
          }
          .menu-btn {
            &.diff {
              display: none;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      min-height: 262px;
    }
  }

  .content {
    .rg-betsson-block,
    .reality-check-block,
    .self-assesment-test-block,
    .gaming-tools-block,
    .gaming-tips-block {
      padding-top: 80px;
      padding-bottom: 80px;
      text-align: center;
      background-color: transparent;
      position: relative;
      scroll-margin-top: 36px;
      .title {
        color: #303132;
        line-height: 1.1;
        margin-bottom: 20px;
        font-size: 48px;
      }
      .subtitle {
        color: #878787;
        font-size: 18px;
        line-height: 1.7;
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 50px;
      }
      @media only screen and (max-width: 1024px) {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      @media only screen and (max-width: 768px) {
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
        max-width: 440px;
        margin: 0 auto;
        .title {
          font-size: 30px;
        }
      }
      @media only screen and (max-width: 480px) {
        scroll-margin-top: 16px;
      }
    }
    .rg-betsson-block {
      background: #f5f5f5;
      border-bottom: 1px solid #ebebeb;
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100vw;
        background: inherit;
        border-bottom: 1px solid #ebebeb;
        top: 0;
        left: -50vw;
        right: -50vw;
        margin-right: auto;
        margin-left: auto;
        z-index: -1;
      }
      .boxes {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;
        .box {
          //   flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          border: 1px solid #dddddd;
          padding: 30px 25px;
          text-align: left;
          font-size: 16px;
          color: #878787;
          .box-image {
            // width: 130px;
            // height: 130px;
            align-self: center;
            margin-bottom: 30px;
            img {
              max-width: 100%;
              height: auto;
            }
          }
          .box-title {
            margin-bottom: 20px;
          }
          .box-desc {
            margin-bottom: 30px;
          }
          &.box-1 {
            width: calc(100% / 2);
            border-top: 0 none;
            border-bottom: 0 none;
            .box-image {
              width: 180px;
              height: 180px;
            }
          }
          &.box-2 {
            width: calc(100% / 2);
            border-left: 0 none;
            border-top: 0 none;
            border-bottom: 0 none;
            .box-image {
              width: 180px;
              height: 180px;
            }
          }
          &.box-3 {
            width: calc(100% / 3);
            .box-image {
              width: 130px;
              height: 130px;
            }
          }
          &.box-4 {
            width: calc(100% / 3);
            border-left: 0 none;
            border-right: 0 none;
            .box-image {
              width: 130px;
              height: 130px;
            }
          }
          &.box-5 {
            width: calc(100% / 3);
            .box-image {
              width: 130px;
              height: 130px;
            }
          }
        }
        @media only screen and (max-width: 850px) {
          flex-direction: column;
          .box {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            &.box-1 {
              width: calc(100% / 1);
              border-top: 0 none;
              border-bottom: 1px solid #dddddd;
              .box-image {
                width: 180px;
                height: 180px;
              }
            }
            &.box-2 {
              width: calc(100% / 1);
              border-left: 1px solid #dddddd;
              .box-image {
                width: 180px;
                height: 180px;
              }
            }
            &.box-3 {
              width: calc(100% / 1);
              .box-image {
                width: 130px;
                height: 130px;
              }
            }
            &.box-4 {
              width: calc(100% / 1);
              border-top: 0 none;
              border-left: 1px solid #dddddd;
              border-right: 1px solid #dddddd;
              .box-image {
                width: 130px;
                height: 130px;
              }
            }
            &.box-5 {
              width: calc(100% / 1);
              .box-image {
                width: 130px;
                height: 130px;
              }
            }
          }
        }
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          .box {
            width: 100% !important;
            max-width: 440px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    .reality-check-block {
      position: relative;
      .subtitle {
        color: #303132;
      }
    }
    .self-assesment-test-block {
      background: #f5f5f5;
      border-bottom: 1px solid #ebebeb;
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100vw;
        background: inherit;
        border-bottom: 1px solid #ebebeb;
        top: 0;
        left: -50vw;
        right: -50vw;
        margin-right: auto;
        margin-left: auto;
        z-index: -1;
      }
    }
    .gaming-tools-block {
      .title {
        font-size: 30px;
      }
      .boxes {
        display: flex;
        justify-content: center;
        align-items: stretch;
        .box {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          border: 1px solid #dddddd;
          padding: 30px 25px;
          text-align: left;
          font-size: 16px;
          color: #878787;
          .box-image {
            width: 92px;
            height: 92px;
            align-self: center;
            margin-bottom: 30px;
            img {
              max-width: 100%;
              height: auto;
            }
          }
          .box-title {
            margin-bottom: 20px;
          }
          .box-desc {
            margin-bottom: 30px;
          }
          &.box-2 {
            border-left: 0 none;
          }
          &.box-3 {
            border-left: 0 none;
            border-right: 0 none;
          }
        }
        @media only screen and (max-width: 880px) {
          flex-direction: column;
          .box {
            width: 100%;
            max-width: 440px;
            margin-left: auto;
            margin-right: auto;
            &.box-2 {
              border: 1px solid #dddddd;
              border-top: 0 none;
            }
            &.box-3 {
              border: 1px solid #dddddd;
              border-top: 0 none;
              border-bottom: 0 none;
            }
          }
        }
      }
      @media only screen and (min-width: 1025px) and (max-width: 1366px) {
        // display: none !important;
      }
    }
    .gaming-tips-block {
      background: #f5f5f5;
      border-bottom: 1px solid #ebebeb;
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100vw;
        background: inherit;
        border-bottom: 1px solid #ebebeb;
        top: 0;
        left: -50vw;
        right: -50vw;
        margin-right: auto;
        margin-left: auto;
        z-index: -1;
      }

      .boxes {
        display: flex;
        justify-content: center;
        align-items: stretch;
        .box {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 30px 25px;
          text-align: left;
          font-size: 16px;
          color: #878787;
          .box-image {
            width: 126px;
            height: 126px;
            align-self: center;
            margin-bottom: 30px;
            img {
              max-width: 100%;
              height: auto;
            }
          }
          .box-title {
            margin-bottom: 20px;
          }
          .box-desc {
            margin-bottom: 30px;
          }
        }
        &.top {
        }
        &.bottom {
          .box {
            &.box-4 {
              .box-image {
                width: 178px;
              }
            }
          }
        }
        @media only screen and (max-width: 880px) {
          flex-direction: column;
          .box {
            width: 100%;
            max-width: 440px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
  // video player
  .plyr__control--overlaid {
    background: #00adef !important;
  }
  .plyr--full-ui.plyr--video .plyr__control--overlaid {
    display: none;
  }
  .plyr--full-ui input[type="range"] {
    color: #00adef !important;
  }
  .plyr--video .plyr__control.plyr__tab-focus,
  .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded="true"] {
    background: #00adef !important;
  }
  .plyr--video .plyr__progress__buffer {
    color: rgba(255, 255, 255, 0.2);
  }
  .plyr__video-wrapper {
    background-color: #fff;
  }
  .video-container {
    border: 1px solid #dddddd;
    color: #fff;
    z-index: 2;
    position: inherit;
    padding: 30px;
    padding-bottom: 40px;
    .video-desc {
      margin-bottom: 30px;
      font-size: 16px;
      color: #878787;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
    }
    .playerHere {
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      width: 100%;
      .vid-player {
        width: 100%;
        z-index: 1;
        border-radius: 0px;
        overflow: hidden;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .vid-player {
      // border-radius: 15px !important;
    }
    .video-container {
      // margin-top: 28px;
    }
    .playerHere {
      // margin-top: 24px !important;
    }
  }
}
</style>
