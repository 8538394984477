const state = {
  langCode: null,
  languages: null,
  isFetching: true,
};

const getters = {
  CurrentLanguageCode(state) {
    return state.langCode;
  },

  AllLanguages(state) {
    return state.languages;
  },

  AllLanguagesExceptNeutral(state) {
    return state.languages?.filter((lang) => lang.code != process.env.VUE_APP_NEUTRAL_LANGUAGE_CODE);
  },

  AllLanguageCodesAsArrayExceptNeutral(state) {
    return state.languages?.filter((lang) => lang.code != process.env.VUE_APP_NEUTRAL_LANGUAGE_CODE).map((lang) => lang.code);
  },

  LanguagesIsFetching(state) {
    return state.isFetching;
  },
};

const actions = {
  setLanguageCode(context, payload) {
    context.commit("SET_LANGUAGE_CODE", payload);
  },

  setLanguages(context, payload) {
    context.commit("SET_LANGUAGES", payload);
  },
};

const mutations = {
  SET_LANGUAGE_CODE(state, payload) {
    state.langCode = payload;
  },

  SET_LANGUAGES(state, payload) {
    state.languages = payload;
    state.isFetching = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
