const state = {
  articles: [
    {
      id: 1,
      title: "20 წლის ქართველი მოტომრბოლელის მხარდამჭერი ევროპაბეთი გახდა",
      country: "Georgia",
      background: "thumb1",
      homeSliderimageSrc: "thumbs/gaxela.jpg",
      articleDesc: `<p>ევროპაბეთი ყველაზე წარმატებული ქართველი მოტომრბოლელის, გიორგი გახელაძის მხარდამჭერი გახდა. 20 წლის უნიჭიერეს სპორტსმენს ეს საშუალებას მისცემს, წლის ყველა მთავარ შეჯიბრზე იასპარეზოს და საკუთარი შესაძლებლობები კონკურენტულ საერთაშორისო ტურნირებზე გამოავლინოს.გახელაძე არა მხოლოდ მოტოსპორტში, არამედ ზოგადად ერთ-ერთი ყველაზე პერსპექტიული ქართველი სპორტსმენია და იგი “იქსტრიმ ენდუროს” კლასის ამომავალ ვარსკვლავად მიიჩნევა. მან უკვე რამდენჯერმე შეძლო იმის დამტკიცება, რომ დიდი პოტენციალი აქვს და შეუძლია, სპორტის ამ სახეობის პირველი ნომერი გახდეს – 20 წლის ასაკამდე ნაჩვენები მისი შედეგები ამის იმედს იძლევა.</p>`,
      articleImgs: ["articles-images/gaxela-img.jpg"],

      reportDescripotion:
        "ევროპაბეთი ყველაზე წარმატებული ქართველი მოტომრბოლელის, გიორგი გახელაძის მხარდამჭერი გახდა. 20 წლის უნიჭიერეს სპორტსმენს ეს საშუალებას მისცემს, წლის ყველა მთავარ შეჯიბრზე იასპარეზოს და საკუთარი შესაძლებლობები კონკურენტულ საერთაშორისო ტურნირებზე გამოავლინოს.",
      articleUrl: "article",
      slug: "gakheladze",
      time: "01.07.2020",
    },
    {
      id: 2,
      title: "სპორტი + ხელოვნება",
      country: "Georgia",
      background: "thumb2",
      homeSliderimageSrc: "thumbs/niko.jpg",
      articleDesc: `<p class="mb-20">“ნიკო” კალათბურთის სტადიონებს მოხატავს. ქუჩის ხელოვნების მოძრაობა Responsible.ge-ს პარტნიორი გახდა. თანამშრომლობის ფარგლებში უბნის სპორტული მოედნები „ნიკოს“ ორგანიზებით გიგანტური ნახატებით დაიფარება. კალათბურთის სტადიონებს ცნობილი არტისტები მოხატავენ. ქუჩის ხელოვნებისა და სპორტის გამაერთიანებელი სივრცე ქვეყნის მასშტაბით 5 მოედანი გახდება.</p> 
            <p>პროექტის მიზანი ერთგვარი ქუჩის გალერეებისა და ახალი ღირსშესანიშნაობების შექმნის გარდა, უბნის სტადიონების გაცოცხლება და სამოყვარულო კალათბურთის პოპულარიზებაა.</p>`,
      articleImgs: ["articles-images/niko-img.jpg"],
      reportDescripotion:
        "“ნიკო” კალათბურთის სტადიონებს მოხატავს. ქუჩის ხელოვნების მოძრაობა Responsible.ge-ს პარტნიორი გახდა. თანამშრომლობის ფარგლებში უბნის სპორტული მოედნები „ნიკოს“ ორგანიზებით გიგანტური ნახატებით დაიფარება. კალათბურთის სტადიონებს ცნობილი არტისტები მოხატავენ.",

      articleUrl: "article",
      slug: "street-art-movement",
      time: "10.05.2021",
    },
    {
      id: 3,
      title: "დათუნა არჯევანიძე & სანდრო თავართქილაძე",
      country: "Georgia",
      background: "thumb3",
      homeSliderimageSrc: "thumbs/ArjevanidzeTavartkiladze_Cover.jpg",
      articleDesc: `<p>დათუნა არჯევანიძე და სანდრო თავართქილაძე ნიჭიერი მრბოლელები არიან, რალი-სპრინტში საქართველოს ჩემპიონატში მონაწილეობენ და საპრიზო ადგილებზე მოხვედრის ამბიციაც აქვთ. მიმდინარე სეზონის სტარტიდან მათ მათ ახალი პარტნიორი ჰყავთ – ევროპაბეთის პასუხისმგებლიანი თამაშის პლატფორმა responsible.ge. თანამშრომლობის მიზანი საქართველოში ახალი ტალანტების წარმოჩენა და მხარდაჭერაა.</p>`,
      articleImgs: ["articles-images/arjevanidze-tavartkiladze-img.jpg"],
      reportDescripotion:
        "დათუნა არჯევანიძე და სანდრო თავართქილაძე ნიჭიერი მრბოლელები არიან, რალი-სპრინტში საქართველოს ჩემპიონატში მონაწილეობენ და საპრიზო ადგილებზე მოხვედრის ამბიციაც აქვთ. მიმდინარე სეზონის სტარტიდან მათ მათ ახალი პარტნიორი ჰყავთ - ევროპაბეთის პასუხისმგებლიანი თამაშის პლატფორმა responsible.ge...",

      articleUrl: "article",
      slug: "arjevanidze-tavartkiladze",
      time: "20.03.2021",
    },
    {
      id: 4,
      title: "MMA საქართველოს ჩემპიონატი",
      country: "Georgia",
      background: "thumb4",
      homeSliderimageSrc: "thumbs/MMA_Cover.jpg",
      articleDesc: `<p>კომპანია ევროპაბეთსა და საქართველოს შერეული საბრძოლო ხელოვნებების ევროვნულ ფედერაციას შორის ურთიერთთანამშრომლობის მემორანდუმი გაფორმდა. ევროპაბეთი სპორტის ამ სახეობას აქტიურად დაუჭერს მხარს და მის განვითარებაში თავის წვლილს შეიტანს. კომპანიის მხარდაჭერით უკვე ჩატარდა საქართველოს ჩემპიონატის რამდენიმე ეტაპი, დაგეგმილია საერთაშორისო ტურნირების ორგანიზება.</p>`,
      articleImgs: ["articles-images/mma-img.jpg"],
      reportDescripotion:
        "კომპანია ევროპაბეთსა და საქართველოს შერეული საბრძოლო ხელოვნებების ევროვნულ ფედერაციას შორის ურთიერთთანამშრომლობის მემორანდუმი გაფორმდა. ევროპაბეთი სპორტის ამ სახეობას აქტიურად დაუჭერს მხარს და მის განვითარებაში თავის წვლილს შეიტანს. კომპანიის მხარდაჭერით უკვე ჩატარდა საქართველოს ჩემპიონატის რამდენიმე ეტაპი, დაგეგმილია საერთაშორისო ტურნირების ორგანიზება.",

      articleUrl: "article",
      slug: "mma-georgia-championship",
      time: "2021-02-09",
    },
    {
      id: 5,
      title: "ნარდის სპორტული ფედერაცია",
      country: "Georgia",
      background: "thumb5",
      homeSliderimageSrc: "thumbs/Nardi_Cover.jpg",
      articleDesc: `<p class="mb-20">ევროპაბეთსა და საქართველოს სპორტული ნარდის ფედერაციას შორის ურთიერთთანამშრომლობის მემორანდუმი 2020 წლის აპრილში გაფორმდა. მას შემდეგ ევროპაბეთი სპორტის ამ სახეობას აქტიურად უჭერს მხარს და მის განვითარებაში საკუთარი წვლილი შეაქვს, შედეგად კი მხარეებმა ნაყოფიერი თანამშრომლობის გაგრძელება კიდევ ერთი წლით გადაწყვიტეს.</p>
            <p>2020 წელს, რომელზეც უდიდესი ზეგავლენა კორონავირუსის პანდემიამ მოახდინა, ევროპაბეთისა და საქართველოს სპორტული ნარდის ფედერაციის თანამშრომლობის ფარგლებში, ჩატარდა რამდენიმე ადგილობრივი და საერთაშორისო ტურნირი.</p>`,
      articleImgs: ["articles-images/nardi-img.jpg"],
      reportDescripotion:
        "ევროპაბეთსა და საქართველოს სპორტული ნარდის ფედერაციას შორის ურთიერთთანამშრომლობის მემორანდუმი 2020 წლის აპრილში გაფორმდა. მას შემდეგ ევროპაბეთი სპორტის ამ სახეობას აქტიურად უჭერს მხარს და მის განვითარებაში საკუთარი წვლილი შეაქვს, შედეგად კი მხარეებმა ნაყოფიერი თანამშრომლობის გაგრძელება კიდევ ერთი წლით გადაწყვიტეს.",
       
      articleUrl: "article",
      slug: "backgammon-federation",
      time: "09.02.2021",
    },
    {
      id: 6,
      title: "საკალათბურთო კლუბი ვერა",
      country: "Georgia",
      background: "thumb6",
      homeSliderimageSrc: "thumbs/Vera_Cover.jpg",
      articleDesc: `<p>კომპანია ევროპაბეთის ქართული სპორტის მხარდამჭერი პროექტების სიას კიდევ ერთი, ამჯერად ახალი საკალათბურთო პროექტი შეემატა.
             ქართული კალათბურთის განვითარებაში კომპანიამ სოლიდური წვლილი 2017 წელსაც შეიტანა, როცა ევროპაბეთი ევრობასკეტზე საქართველოს ეროვნული საკალათბურთო ნაკრების სპონსორი იყო. ამჯერად, ევროპაბეთი საკალათბურთო კლუბ ვერას გენერალური სპონსორი გახდა. ამასთან, ვერას მთავარი მედია-პარტნიორი პოპსპორტი იქნება.<p>`,
      articleImgs: ["articles-images/vera-img.jpg"],
      reportDescripotion:
        "კომპანია ევროპაბეთის ქართული სპორტის მხარდამჭერი პროექტების სიას კიდევ ერთი, ამჯერად ახალი საკალათბურთო პროექტი შეემატა. ქართული კალათბურთის განვითარებაში კომპანიამ სოლიდური წვლილი 2017 წელსაც შეიტანა, როცა ევროპაბეთი ევრობასკეტზე საქართველოს ეროვნული საკალათბურთო ნაკრების სპონსორი იყო. ამჯერად, ევროპაბეთი საკალათბურთო კლუბ ვერას გენერალური სპონსორი გახდა...",

      articleUrl: "article",
      slug: "basketballclubvera",
      time: "05.02.2021",
    },
    {
      id: 7,
      title: "Responsible.ge-სა და „კამარას“ თანამშრომლობის 1 წელი",
      country: "Georgia",
      background: "thumb7",
      homeSliderimageSrc: "thumbs/Statia-Cover.jpg",
      articleDesc: `<p class="mb-20">ფსიქორეაბილიტაციის უფასო კურსები თამაშზე დამოკიდებულთათვის <a href="https://responsible.ge" class="light-blue cursor-pointer">„კამარაში“  Responsible.ge</a>-ს დაფინანსებით 1 წელია მიმდინარეობს. 2020 წლის მარტში „კამარა“ იყო პირველი ფსიქო-სოციალური რეაბილიტაციის ცენტრი, რომელიც ევროპაბეთის პასუხისმგებლიანი თამაშის პლატფორმის პარტნიორი გახდა და თამაშზე დამოკიდებული ადამიანებისთვის კომპლექსური ფსიქოთერაპიული კურსები გამოაცხადა.</p>
            <p class="mb-20">პროექტის დაწყებიდან 1 წლის თავზე კამარა და responsible.ge შედეგებს <a href="https://vimeo.com/534314537" target="_blank" class="light-blue cursor-pointer no-underline">აჯამებენ.</a></p>
            <p class="mb-20">ამ დროის განმავლობაში ცენტრს ფსიქოთერაპიული სერვისისთვის  190-მა ბენეფიციარმა მიმართა.თერაპიული კურსი წარმატებით დასრულებული აქვს 23 პაციენტს.დღევანდელი მონაცემებით თერაპიას გადის 59 პაციენტი.</p>
            <p class="mb-20 text-italic"><span class="font-hl-bold ">ნათია ფანჯიკიძე, კამარას ხელმძღვანელი, ფსიქოლოგი:</span> „მეთოდს, რომელსაც კამარაში დამოკიდებულებებთან სამუშაოდ ვიყენებთ ძალიან მაღალი გამარჯვების მაჩვენებელი აქვს.პრაქტიკულად ყველა პაციენტი ინარჩუნებს ხანგრძლივ რემისიას და ბევრმა ადამიანმა შეცვალა ცხოვრება.შესაბამისად დღევანდელი გადასახედიდან კიდევ უფრო დარწმუნებით შეგვიძლია ვთქვათ, რომ ეს თანამშრომლობა სწორი ნაბიჯი იყო.ცხადია გლობალური პრობლემის დასაძლევად ერთი თანამშრომლობა, ინიციატივა ან თუნდაც ცენტრი საკმარისი არ არის.ეს არის პირველი ნაბიჯი, რომელიც ვფიქრობ სხვა მხარეების ჩართულობით გადახდის უუნარო სეგმენტისთვის ფსიქოსარეაბილიტაციო კურსების ხელმისაწვდომობის გაზრდით უნდა გაგრძელდეს.“</p>
            <p class="mb-20 text-italic">„ეს პრაქტიკა, როცა სათამაშო ბიზნესი ფსიქორეაბილიტაციის პროგრამებს აფინანსებს, საკმაოდ კარგად მუშაობს ევროპაში.პროექტის დასაწყისში ჩვენი მიზანი იყო არა მარტო კომპანია, არამედ ინდუსტრია განგვევითარებინა და სოციალური პასუხისმგებლობის ეს მოდელი შემოგვეტანა ბაზარზე.</p>
            <p class="mb-20 text-italic">1 წლის წინ „ევროპაბეთისა“ და „კამარას“ თანამშრომლობის დაანონსება გაბედული და  ხმაურიანი ნაბიჯი იყო ორივე მხარისგან.გვიხარია, რომ პროგრამაზე მოთხოვნაც და შედეგებიც პროექტის აუცილებლობაზე მიანიშნებს.ვფიქრობთ, საბოლოოდ ასეთი შესაძლებლობების გამრავლებასა და ფსიქოთერაპიის ხელმისაწვდომობის გაზრდაში გემბლინგ ინდუსტრიის სხვა წარმომადგენლებიც ჩაერთვებიან.“  – აღნიშნავს <span class="font-hl-bold ">ლაშა გოგუა, „ევროპაბეთის“ მარკეტინგის დირექტორი.</span></p>
            <p class="mb-20">2020 წლის მარტიდან პროექტის ფარგლებში კამარაში თერაპიის 1248 საათი ჩატარდა.თერაპიული სეანსები პარალელურად მიმდინარეობს სამ ჯგუფში, კვირაში ოთხი დღე, ოთხ სხვადასხვა თერაპევტთან.</p>
            <p class="mb-20">კურსების დაწყებიდან 3 კვირაში ქვეყანაში კარანტინი გამოცხადდა, თუმცა ინტენსიური ფსიქოთერაპიის პროგრამა არ შეჩერებულა.</p>
            <p class="mb-20 text-italic">„მოგვიწია თერაპიის პროცესის ონლაინში გადატანა.მიუხედავად იმისა, რომ დისტანციურმა შეხვედრებმა ზოგიერთი თერაპიის ჩატარებისთვის აუცილებელი ინსტრუმენტები გამოგვაცალა, მთლიანობაში, მეთოდის გადატანა მოხერხდა და ჩვენდა სასიხარულოდ, შედეგმა გაამართლა.მოგვემატნენ ახალი ბენეფიციარებიც, მათ შორის, საქართველოს სხვა ქალაქებში მცხოვრები პირები.აქედან გამომდინარე, გარდა თბილისში მცხოვრები ბენეფიციარების ადგილზე მომსახურებისა, ონლაინ პრაქტიკა აღარ გავაუქმეთ.დავტოვეთ, როგორც უკვე აპრობირებული, დამატებით შესაძლებლობა დედაქალაქის გარეთ მცხოვრები ბენეფიციარებისთვის.” <span class="font-hl-bold">– ნინუცა კომახიძე, „კამარას“ ფსიქოლოგი.</span"></p>
            <p class="mb-20">ფსიქორებაილიტაციის უფასო კურსები თამაშზე დამოკიდებულთათვის „კამარაში“ გრძელდება.პროგრამაში რეგისტრაციისთვის მსურველებმა უნდა მიმართონ ცენტრს ნომერზე 032 298 21 95.</p>
            <p class="mb-20">„ევროპაბეთმა“ პასუხისმგებლიანი თამაშის ევროპული მოდელის დანერგვა ინდუსტრიაში 2019 წლის ბოლოდან დაიწყო.ბეთსონ ჯგუფის წარმომადგენელი საქართველოში ჯგუფის ერთ - ერთ მთავარ ღირებულებას პასუხისმგებლიანი ოპერირების კონცეფციას იზიარებს და ქართულ ბაზარზე ახალი კულტურის შემოტანას განაგრძობს.ევროპაბეთის პასუხისმგებლიანი თამაშის პოლიტიკა სამ მიმართულებას მოიცავს.ესაა <span class="font-hl-bold">პასუხისმგებლობა ბიზნესში, პასუხისმგებლობა კომუნიკაციაში და პასუხისმგებლობა საზოგადოების მიმართ.</span>კორპორაციული სოციალური პასუხისმგებლობის ფარგლებში კომპანია პროექტებს თითოეული მიმართულებით ახორციელებს.</p>`,
      articleImgs: ["articles-images/statia-img.jpg"],
      video: "534314537",
      reportDescripotion:
        "ფსიქორეაბილიტაციის უფასო კურსები თამაშზე დამოკიდებულთათვის „კამარაში“  Responsible.ge-ს დაფინანსებით 1 წელია მიმდინარეობს. 2020 წლის მარტში „კამარა“ იყო პირველი ფსიქო-სოციალური რეაბილიტაციის ცენტრი, რომელიც ევროპაბეთის პასუხისმგებლიანი თამაშის პლატფორმის პარტნიორი გახდა...",
      articleUrl: "article",
      slug: "oneyearofcollaboration",
      time: "25.04.2021",
    },
    {
      id: 8,
      title: 'ფსიქორეაბილიტაციის ცენტრი „მენტალი ჰაბი“',
      country: "Georgia",
      background: "thumb8",
      homeSliderimageSrc: "thumbs/mentalhub.jpg",
      articleDesc: `<p>Responsible.ge-ს მხარდაჭერით ახალი ფსიქორეაბილიტაციის ცენტრი <a href="https://mentalhub.ge/" target="_blank" class="light-blue cursor-pointer no-underline">„მენტალი ჰაბი“</a>  გაიხსნა. პასუხისმგებლიანი თამაშის კამპანიასთან პარტნიორობის ფარგლებში, ევროპაბეთის დაფინანსებით ცენტრი უფასო ფსიქოთერაპიულ დახმარებას ლუდომანიით დაავადებული ადამიანების გარდა იმ სამედიცინო პერსონალსაც გაუწევს, ვინც უკვე რამდენიმე თვეა covid-19-ის წინააღმდეგ ბრძოლაშია ჩართული.</p>`,
      articleImgs: ["articles-images/mentalhub-img.jpg"],
      reportDescripotion:
        '„მენტალ ჰაბი“ ევროპაბეთის მხარდაჭერით  გაიხსნა და გახდა მეორე ფსიქო-სოციალური რეაბილიტაციის ცენტრი, რომელიც პასუხისმგებლიანი თამაშის კამპანიის პარტნიორი გახდა...',

      articleUrl: "article",
      slug: "ფსიქორეაბილიტაციის-ცენტ",
      time: "25.06.2020",
    },
    {
      id: 9,
      title: "ურთიერთთანამშრომლობის მემორანდუმი ჭადრაკის ფედერაციასთან.",
      country: "Georgia",
      background: "thumb9",
      homeSliderimageSrc: null,
      articleDesc: `<p>ევროპაბეთის მხარდაჭერით საქართველოს ჩემპიონატი ვაჟთა და ქალთა შორის მომავალ წელს გაორმაგებული საპრიზო ფონდით ჩატარდება. კომპანია იზრუნებს ჭადრაკის პოპულარიზაციაზე და სპორტის ამ სახეობის განვითარების მიზნით სხვადასხვა პროექტს განახორციელებს. </p>`,
      articleImgs: [
        "articles-images/chess-federation-img-1.jpg",
        "articles-images/chess-federation-img-2.jpg",
        "articles-images/chess-federation-img-3.jpg",
        "articles-images/chess-federation-img-4.jpg",
        "articles-images/chess-federation-img-5.jpg",
        "articles-images/chess-federation-img-6.jpg",
        "articles-images/chess-federation-img-7.jpg",
      ],
      reportDescripotion:
        "Responsible.ge-სა და ჭადრაკის ფედერაციას შორის თანამშრომლობის ფარგლებში ევროპაბეთი იზრუნებს ჭადრაკის პოპულარიზაციაზე და სპორტის ამ სახეობის განვითარების მიზნით, სხვადასხვა პროექტების განხორციელებაში მიიღებს მონაწილეობას. კომპანიის დახმარებით მომდევნო წლებში ქალთა და ვაჟთა შორის ჩემპიონატები გაორმაგებული საპრიზო ფონდით გაიმართება.",

      articleUrl: "article",
      slug: "chess-federation",
    },
    {
      id: 10,
      title: "ყველაზე ძლიერი ქართველები, რომლებსაც მთელი მსოფლიო იცნობს",
      country: "Georgia",
      background: "thumb10",
      homeSliderimageSrc: null,
      articleDesc: `<p>ევროპაბეთი საქართველოს უძლიერეს მამაკაცთა ეროვნული ფედერაციის გენერალური სპონსორი და მხარდამჭერი გახდა. ქართველ ხარს მთელი მსოფლიო იცნობს და ყველა დიდი ტურნირის ფავორიტთა შორის არის. მის მაგალითზე სპორტის ამ სახეობით ძალიან ბევრი ადამიანი დაინტერესდა, მომდევნო წლების განმავლობაში კი ევროპაბეთი მათი მხარდამჭერი იქნება. </p>`,
      articleImgs: [
        "articles-images/strongmen-img-1.jpg",
        "articles-images/strongmen-img-2.jpg",
        "articles-images/strongmen-img-3.jpg",
        "articles-images/strongmen-img-4.jpg",
        "articles-images/strongmen-img-5.jpg",
        "articles-images/strongmen-img-6.jpg",
        "articles-images/strongmen-img-7.jpg",
        "articles-images/strongmen-img-8.jpg",
        "articles-images/strongmen-img-9.jpg",
        "articles-images/strongmen-img-10.jpg",
        "articles-images/strongmen-img-11.jpg",
        "articles-images/strongmen-img-12.jpg",
        "articles-images/strongmen-img-13.jpg",
      ],

      articleUrl: "article",
      slug: "strongmen",
    },
    {
      id: 11,
      title: "ერთად ვწერთ ისტორიას – ევროპაბეთი ბათუმის დინამოს გენერალური სპონსორია",
      country: "Georgia",
      background: "thumb11",
      homeSliderimageSrc: null,
      articleDesc: `<p>ათუმის დინამოს ისტორიაში ახალი ეპოქა დაიწყო. დიდი საფეხბურთო ისტორიისა და ტრადიციების მქონე აჭარულ კლუბში ევროპაბეთი ახალი გენერალური სპონსორის სტატუსით მივიდა. დინამოსთან 1,5-წლიანი კონტრაქტი გაფორმდა და ამ პერიოდში კლუბში 600 000 ლარზე მეტი ინვესტიცია განხორციელდება. </p>`,
      articleImgs: [
        "articles-images/dinamo-batumi-img-1.jpg",
        "articles-images/dinamo-batumi-img-2.jpg",
        "articles-images/dinamo-batumi-img-3.jpg",
        "articles-images/dinamo-batumi-img-4.jpg",
        "articles-images/dinamo-batumi-img-5.jpg",
        "articles-images/dinamo-batumi-img-6.jpg",
        "articles-images/dinamo-batumi-img-7.jpg",
        "articles-images/dinamo-batumi-img-8.jpg",
        "articles-images/dinamo-batumi-img-9.jpg",
        "articles-images/dinamo-batumi-img-10.jpg",
      ],

      articleUrl: "article",
      slug: "dinamobatumi",
    },
    {
      id: 12,
      title: "სპორტი, დასვენება და გართობა – ღონისძიებების სერია",
      country: "Georgia",
      background: "thumb12",
      homeSliderimageSrc: null,
      articleDesc: `<p>ევროპაბეთის მხარდაჭერით მთელი წლის განმავლობაში მიმდინარეობდა პროექტი “აქტიური ცხოვრება ჩემი სტიქიაა”. ომის ვეტერანებისთვის, სამხედრო მისიებში დაჭრილი ჯარისკაცებისთვის, პარასპორტსმენებისა და შეზღუდული შესაძლებლობების მქონე სხვა პირებისთვის ჯამში 8 მასშტაბური ღონისძიება მოეწყო. ინტეგრირებული ტურნირი ბოულინგში; სტენდური სროლა, გოლფი და ცხენოსნობა; პარაპლანით ფრენა; მკლავჭიდი და ბოჩა ვაჟაობაზე; ჯომარდობა და სპორტული ცეკვები; მშვილდოსნობა და პეინტბოლი; ზიპლაინი და კულინარიული დუელი და ყველაფრის კულმინაცია – ევროპაბეთის საზაფხულო ბანაკი ბორჯომში.</p>`,
      articleImgs: [
        "articles-images/sportandfun-img-1.jpg",
        "articles-images/sportandfun-img-2.jpg",
        "articles-images/sportandfun-img-3.jpg",
        "articles-images/sportandfun-img-4.jpg",
        "articles-images/sportandfun-img-5.jpg",
        "articles-images/sportandfun-img-6.jpg",
        "articles-images/sportandfun-img-7.jpg",
        "articles-images/sportandfun-img-8.jpg",
        "articles-images/sportandfun-img-9.jpg",
        "articles-images/sportandfun-img-10.jpg",
        "articles-images/sportandfun-img-11.jpg",
        "articles-images/sportandfun-img-12.jpg",
        "articles-images/sportandfun-img-13.jpg",
        "articles-images/sportandfun-img-14.jpg",
        "articles-images/sportandfun-img-15.jpg",
        "articles-images/sportandfun-img-16.jpg",
        "articles-images/sportandfun-img-17.jpg",
        "articles-images/sportandfun-img-18.jpg",
        "articles-images/sportandfun-img-19.jpg",
        "articles-images/sportandfun-img-20.jpg",
        "articles-images/sportandfun-img-21.jpg",
        "articles-images/sportandfun-img-22.jpg",
        "articles-images/sportandfun-img-23.jpg",
        "articles-images/sportandfun-img-24.jpg",
        "articles-images/sportandfun-img-25.jpg",
        "articles-images/sportandfun-img-26.jpg",
        "articles-images/sportandfun-img-27.jpg",
      ],

      articleUrl: "article",
      slug: "sportandfun",
    },
    {
      id: 13,
      title: "ყვარლის ტბაზე ბაიკით! დიდი გამბედაობის ამბავი",
      country: "Georgia",
      background: "thumb13",
      homeSliderimageSrc: null,
      articleDesc: `<p>თქვენც ფიქრობთ, რომ მოტოციკლს წყალზე არაფერი ესაქმება? ბაიკის ადგილი ყვარლის ტბის ზედაპირზე არ არის? არსებობენ ადამიანები, რომლებიც ასე არ ფიქრობენ…</p>`,
      articleImgs: [
        "articles-images/kvarelilake-img-1.jpg",
        "articles-images/kvarelilake-img-2.jpg",
        "articles-images/kvarelilake-img-3.jpg",
        "articles-images/kvarelilake-img-4.jpg",
        "articles-images/kvarelilake-img-5.jpg",
        "articles-images/kvarelilake-img-6.jpg",
        "articles-images/kvarelilake-img-7.jpg",
        "articles-images/kvarelilake-img-8.jpg",
        "articles-images/kvarelilake-img-9.jpg",
        "articles-images/kvarelilake-img-10.jpg",
        "articles-images/kvarelilake-img-11.jpg",
      ],

      articleUrl: "article",
      slug: "kvarelilake",
    },
    {
      id: 14,
      title: "POP X Spotlight",
      country: "Georgia",
      background: "thumb14",
      homeSliderimageSrc: null,
      articleDesc: `<p class="mb-20">ევროპაბეთმა თავის ქვე-ბრენდს – pop-ს კიდევ ერთი მულტიმედია პლატფორმა popx დაუმატა. ეს არის უნიკალური პლატფორმა, რომელიც მიზნად ტექნოლოგიებისა და ინოვაციების მზარდი სფეროს პოპულარიზაციას ისახავს. ასევე, ნოვატორ და ჯერ უცნობ გამომგონებლებს თავიანთი იდეების მომხმარებლამდე მიტანაში ეხმარება.</p>
           <p> 2018 წელს ახლადშექმნილი პლატფორმისა და Marketer-ის თანამშრომლობით გაიმართა ღონისძიება “გაბედე”, რომელიც მთლიანად ინოვაციებსა და ახალ ტექნოლოგიებს დაეთმო. “გაბედე”  პირველი მარკეტინგული ღონისძიების, Spotlight-ის სერიის ნაწილი იყო.</p>`,
      articleImgs: [
        "articles-images/popxspotlight-img-1.jpg",
        "articles-images/popxspotlight-img-2.jpg",
        "articles-images/popxspotlight-img-3.jpg",
        "articles-images/popxspotlight-img-4.jpg",
        "articles-images/popxspotlight-img-5.jpg",
        "articles-images/popxspotlight-img-6.jpg",
        "articles-images/popxspotlight-img-7.jpg",
        "articles-images/popxspotlight-img-8.jpg",
      ],

      articleUrl: "article",
      slug: "popxspotlight",
    },
    {
      id: 15,
      title: "ევრობასკეტი 2017",
      country: "Georgia",
      background: "thumb15",
      homeSliderimageSrc: null,
      articleDesc: `<p>2017 წლის ევროპის საკალათბურთო ჩემპიონატზე ევროპაბეთი ეროვნული გუნდის გენერალური სპონსორი იყო. კომპანიასა და კალათბურთის ფედერაციას შორის ხელშეკრულება ტურნირის დაწყებამდე 3 თვით ადრე დაიდო და თანამშრომლობის პერიოდში, ევროპაბეთი ქართულ კალათბურთში ინვესტირების პარალელურად, ეროვნული გუნდის სრულ მედია მხარდაჭერასაც ახორციელებდა.</p>`,
      articleImgs: [
        "articles-images/eurobasket-img-1.jpg",
        "articles-images/eurobasket-img-2.jpg",
        "articles-images/eurobasket-img-3.jpg",
        "articles-images/eurobasket-img-4.jpg",
        "articles-images/eurobasket-img-5.jpg",
        "articles-images/eurobasket-img-6.jpg",
        "articles-images/eurobasket-img-7.jpg",
        "articles-images/eurobasket-img-8.jpg",
        "articles-images/eurobasket-img-9.jpg",
        "articles-images/eurobasket-img-10.jpg",
        "articles-images/eurobasket-img-11.jpg",
        "articles-images/eurobasket-img-12.jpg",
        "articles-images/eurobasket-img-13.jpg",
        "articles-images/eurobasket-img-14.jpg",
        "articles-images/eurobasket-img-15.jpg",
      ],

      articleUrl: "article",
      slug: "eurobasket2017",
    },
    {
      id: 16,
      title: "Fly Fest Georgia",
      country: "Georgia",
      background: "thumb16",
      homeSliderimageSrc: null,
      articleDesc: `<p>საქართველოში პირველი ავიაშოუ ევროპაბეთის მხარდაჭერით გაიმართა. ნატახტარის აეროდრომზე იყო ყველაფერი: რეაქტიული და სპორტული თვითმფრინავები, პარაშუტით ხტომა, პარაპლანით ფრენა, სხვადასხვა საფრენი აპარატებით მანევრირება. შოუს მთავარი ვარსკვლავები ბალტიისპირელი ფუტკრები იყვნენ, რომლებმაც აეროდრომზე შეკრებილ ადამიანებს საოცარი სანახაობა აჩუქეს.</p>`,
      articleImgs: [
        "articles-images/flyfestgeorgia-img-1.jpg",
        "articles-images/flyfestgeorgia-img-2.jpg",
        "articles-images/flyfestgeorgia-img-3.jpg",
        "articles-images/flyfestgeorgia-img-4.jpg",
        "articles-images/flyfestgeorgia-img-5.jpg",
      ],

      articleUrl: "article",
      slug: "flyfestgeorgia",
    },
    {
      id: 17,
      title: "Green Gift",
      country: "Georgia",
      background: "thumb17",
      homeSliderimageSrc: null,
      articleDesc: `<p class="mb-20">ბუნების დაცვისა და ქაღალდის გადამუშავების მიზნით ოფისში მწვანე ყუთები დავდგით. ოფისში დაგროვებულ უსარგებლო ქაღალდს ამ ყუთში ვათავსებთ, რომელიც ქაღალდის გადამამუშავებელ კომპანიას ეგზავნება.</p>
            <p>ჩვენ ევროპაბეთში, ვზრუნავთ ჩვენს გარემოზე.</p>`,
      articleImgs: ["articles-images/green-gift-img.jpg"],

      articleUrl: "article",
      slug: "green-gift",
    },
    {
      id: 18,
      title: "Rock IT  – ფესტივალი კლდეზე ცოცვაში",
      country: "Georgia",
      background: "thumb18",
      homeSliderimageSrc: null,
      articleDesc: `<p>ფესტივალი კლდეზე ცოცვაში ყოველწლიურად იმართება, ერთი სეზონის განმავლობაში Rock IT-ის მხარდამჭერებს შორის კი ევროპაბეთიც იყო. ბოლდერინგი, ქვედა და ზედა დაცვით ცოცვა… ბოტანიკურ ბაღსა და ლოტკინის მონუმენტთან მისულ ადამიანებს ამ  ყველაფრისთვის თვალის მიდევნებაც და საკუთარი ძალების გამოცდა შეეძლოთ.</p>`,
      articleImgs: [
        "articles-images/rockit-img-1.jpg",
        "articles-images/rockit-img-2.jpg",
        "articles-images/rockit-img-3.jpg",
        "articles-images/rockit-img-4.jpg",
        "articles-images/rockit-img-5.jpg",
        "articles-images/rockit-img-6.jpg",
        "articles-images/rockit-img-7.jpg",
        "articles-images/rockit-img-8.jpg",
        "articles-images/rockit-img-9.jpg",
        "articles-images/rockit-img-10.jpg",
        "articles-images/rockit-img-11.jpg",
        "articles-images/rockit-img-12.jpg",
        "articles-images/rockit-img-13.jpg",
        "articles-images/rockit-img-14.jpg",
      ],

      articleUrl: "article",
      slug: "rockit",
    },
    {
      id: 19,
      title: "წყვილთა რბოლა – ექსტრემალური სპორტი",
      country: "Georgia",
      background: "thumb19",
      homeSliderimageSrc: null,
      articleDesc: `<p>ჰასკი მოტოპარკში რბოლები რეგულარულად ტარდება, ევროპაბეთი სპორტის ამ ექსტრემალური სახეობის დიდი ქომაგია. სამთო მოტოციკლებით სხვადასხვა დაბრკოლებებისა და ტრამპლინების გადალახვა დიდ ძალისხმევას მოითხოვს. ენდურო კროსის ტრასაზე მიმდინარე შოუს ყოველთვის ბევრი ადამიანი ესწრება და იგი განსაკუთრებულ ყურადღებას იქცევს.</p>`,
      articleImgs: [
        "articles-images/doublerace-img-1.jpg",
        "articles-images/doublerace-img-2.jpg",
        "articles-images/doublerace-img-3.jpg",
        "articles-images/doublerace-img-4.jpg",
        "articles-images/doublerace-img-5.jpg",
        "articles-images/doublerace-img-6.jpg",
        "articles-images/doublerace-img-7.jpg",
        "articles-images/doublerace-img-8.jpg",
        "articles-images/doublerace-img-9.jpg",
        "articles-images/doublerace-img-10.jpg",
        "articles-images/doublerace-img-11.jpg",
        "articles-images/doublerace-img-12.jpg",
        "articles-images/doublerace-img-13.jpg",
        "articles-images/doublerace-img-14.jpg",
      ],

      articleUrl: "article",
      slug: "doublerace",
    },
    {
      id: 20,
      title: "კალათბურთის სამოყვარულო ლიგა",
      country: "Georgia",
      background: "thumb20",
      homeSliderimageSrc: null,
      articleDesc: `<p>კალათბურთის სამოყვარულო ლიგა მიზნად ისახავს, ხელი შეუწყოს საქართველოში სპორტის ამ სახეობის პოპულარიზაციასა და განვითარებას. მოყვარულებს შორის გამართულ ტურნირში მონაწილეობას ვეტერანები და არაპროფესიონალი მოთამაშეები იღებენ. ევროპაბეთი ლიგის მხარდამჭერი 1 სეზონის განმავლობაში იყო და დაინტერესებულ ადამიანებს ყოველი ტურის ვიდეო მიმოხილვასაც სთავაზობდა.</p>`,
      articleImgs: [
        // 'articles-images/amateurbasketball-img-0.jpg',
        "articles-images/amateurbasketball-img-1.jpg",
        "articles-images/amateurbasketball-img-2.jpg",
        "articles-images/amateurbasketball-img-3.jpg",
        "articles-images/amateurbasketball-img-4.jpg",
        "articles-images/amateurbasketball-img-5.jpg",
        "articles-images/amateurbasketball-img-6.jpg",
        "articles-images/amateurbasketball-img-7.jpg",
        "articles-images/amateurbasketball-img-8.jpg",
        "articles-images/amateurbasketball-img-9.jpg",
        "articles-images/amateurbasketball-img-10.jpg",
        "articles-images/amateurbasketball-img-11.jpg",
        "articles-images/amateurbasketball-img-12.jpg",
        "articles-images/amateurbasketball-img-13.jpg",
        "articles-images/amateurbasketball-img-14.jpg",
      ],

      articleUrl: "article",
      slug: "amateurbasketball",
    },
    {
      id: 21,
      title: "გლდანის ბავშვთა სახლი",
      country: "Georgia",
      background: "thumb21",
      homeSliderimageSrc: null,
      articleDesc: `<p class="mb-20">ეს სახელმწიფო პროექტია, რაც იმას ნიშნავს, რომ ბავშვები თავს ისე გრძნობენ, როგორც დიდ და თბილ ოჯახში. გლდანში ერთი პატარა კერძო შენობაა, სადაც 10 არასრულწლოვანი მასწავლებლებთან და კურატორებთან ერთად ცხოვრობს. მათთან ურთიერთობა 2017 წლის ოქტომბერში დავიწყეთ, შემდეგ სტუმრადაც ვეწვიეთ და ერთად ვივახშმეთ კიდეც. ბავშვებს საახალწლო სურვილები დავაწერინეთ და სანტასთან გაგზავნას დავპირდით. შემდეგ კი ფული შევაგროვეთ და მათი ოცნებები ავასრულეთ. ისინი ჩვენს ოფისში დავპატიჟეთ, სადაც საჩუქრები გადავეცით.</p>
            <p>წელს კი, სააღდგომოდ პასკები და ნამცხვრები გავუგზავნეთ. უახლოეს მომავალში პატარა წარმოდგენის გამართვასაც ვგეგმავთ.</p>`,
      articleImgs: [
        "articles-images/gldanichildrenhouse-img-1.jpg",
        "articles-images/gldanichildrenhouse-img-2.jpg",
        "articles-images/gldanichildrenhouse-img-3.jpg",
        "articles-images/gldanichildrenhouse-img-4.jpg",
      ],

      articleUrl: "article",
      slug: "gldanichildrenhouse",
    },
    {
      id: 22,
      title: "Colour My Run 2019",
      country: "Georgia",
      background: "thumb22",
      homeSliderimageSrc: null,
      articleDesc: `<p class="mb-20">ბეთსონ ჯგუფმა 2019 წელს Colour My Run 2019 დააფინანსა. ეს 5კმ-იანი ყველაზე ფერადი და მხიარული მონაკვეთია მალტაში. მონაწილეები ფერად გაჩერებებსა და ქაფის აბაზანებს გადიან, სადაც მათი მაისურები ფერს იცვლის.</p>
            <p class="mb-20">ეს ღონისძიება მხარს უჭერს Inspire-ს და მათ წამოწყებას, რომელიც განსაკუთრებული საჭიროების მქონე პირებისა და მათი ოჯახების დახმარებას გულისხმობს.</p>`,

      articleImgs: ["articles-images/cmr-img.jpg"],

      articleUrl: "article",
      slug: "colour-my-run-2019",
    },
    {
      id: 23,
      title: "Beating Hearts Foundation",
      country: "Georgia",
      background: "thumb23",
      homeSliderimageSrc: null,
      articleDesc: `<p class="mb-20">2019 წლის აგვისტოში Ftira Day მოვაწყეთ მალტის ოფისში. ვიყიდეთ პური და სხვადასხვა ინგრედიენტი. ჩვენმა თანამშრომლებმა ძალიან გემრიელი ბურგერები გააკეთეს და შემდეგ Beating Hearts Malta-ს ფარგლებში გაყიდეს. ეს პირველი ასოციაციაა მალტაში, რომელიც უფროსებისა და ბავშვებისთვის გულის თანდაყოლილი დეფექტების მკურნალობას ემსახურება.</p>`,
      articleImgs: [
        "articles-images/beatinghearts-img-1.jpg",
        "articles-images/beatinghearts-img-2.jpg",
        "articles-images/beatinghearts-img-3.jpg",
        "articles-images/beatinghearts-img-4.jpg",
      ],

      articleUrl: "article",
      slug: "beating-hearts-foundation",
    },
    {
      id: 24,
      title: "Catharsis",
      country: "Georgia",
      background: "thumb24",
      homeSliderimageSrc: null,
      articleDesc: `<p class="mb-20">მოხუცებს ყოველთვიურად ვეხმარებით. კედელზე გამოკრული პოსტერით მოხუცებს ვახარებთ, რომ დღეს მათ ევროპაბეთი უმასპინძლდებათ. 2017 წელს მათ პირადად ვეწვიეთ და ოფიციანტობაც შევითავსეთ.</p>`,
      articleImgs: ["articles-images/catharsis-img.jpg"],

      articleUrl: "article",
      slug: "catharsis-georgia",
    },
    {
      id: 25,
      title: "სისხლის დონაცია",
      country: "Georgia",
      background: "thumb25",
      homeSliderimageSrc: null,
      articleDesc: `<p class="mb-20">2015 წლიდან სისხლის ეროვნული ბანკის დახმარებით ჩვენი თანამშრომლები სისხლის გაცემაში მონაწილეობენ. ვთვლით, რომ ეს ერთ-ერთი ყველაზე გულწრფელი საშუალებაა, რომელიც კარგად წარმოაჩენს, რამდენად ვზრუნავთ ჩვენ საზოგადოებაზე. გვსურს, რომ სისხლის გაცემამ კვარტალური ხასიათი მიიღოს რაც პოპულარიზაციას გაუწევს აღნიშნულ ფაქტს და გაზრდის ცნობადობას, თუ რამდენად მნიშვნელოვანია ეს ქმედება.</p>`,
      articleImgs: ["articles-images/blood-drive-img-1.jpg", "articles-images/blood-drive-img-2.jpg", "articles-images/blood-drive-img-3.jpg"],

      articleUrl: "article",
      slug: "blood-drive-georgia",
    },
    {
      id: 26,
      title: "Wings for Life",
      country: "Georgia",
      background: "thumb26",
      homeSliderimageSrc: null,
      articleDesc: `<p class="mb-20">2014 წლიდან ყოველწლიურად Wings for Life-ში ვიღებთ მონაწილეობას. ჩვენი მიზანია მეცნიერებს ზურგის ტვინის დაზიანებების განკურნებაში დავეხმაროთ. ფონდს ყოველწლიურ რბოლაში მონაწილეობით ვეხმარებით. მონაწილეობისა და გადარიცხული მთლიანი თანხა, ზურგის ტვინის დაავადებების კვლევას ხმარდება.</p>`,
      articleImgs: [
        "articles-images/wings-for-life-img-1.jpg",
        "articles-images/wings-for-life-img-2.jpg",
        "articles-images/wings-for-life-img-3.jpg",
      ],

      articleUrl: "article",
      slug: "wings-for-life",
    },
  ],
};

const getters = {
  allArticles(state) {
    return state.articles;
  },
};

const actions = {};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
