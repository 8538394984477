const state = {
  data: null,
  isFetching: true,
};

const getters = {
  AllContent(state) {
    return state.data;
  },
};

const actions = {
  setContent(context, payload) {
    context.commit("SET_CONTENT", payload);
  },
};

const mutations = {
  SET_CONTENT(state, payload) {
    state.data = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
