const state = {
  isFetching: true,
  data: null,
};

const getters = {
  UserPinCode(state) {
    return state.data?.basicInformation?.pinCode;
  },

  UserGuid(state) {
    return state.data?.customerId;
  },

  Username(state) {
    return state.data?.loginInformation?.username;
  },

  UserData(state) {
    return state.data;
  },

  IsAuthorized(state) {
    return state.data?.customerId ? true : false;
  },
  UserDataIsFetching(state) {
    return state.isFetching;
  },
};

const actions = {
  setUserData(context, payload) {
    context.commit("SET_USER_DATA", payload);
  },
};

const mutations = {
  SET_USER_DATA(state, payload) {
    state.isFetching = false;
    state.data = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
