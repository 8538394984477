<template>
  <div class="report-component eb-font-roman">
    <div class="header" id="reportHeader">
      <div class="header-bg"></div>
      <div class="wrapper">
        <div class="header-text">
          <div class="header-text-inner">
            <h3 class="isTitle font-hl-bold">პასუხისმგებლიანი თამაშის 6 თვე</h3>
            <div class="isDesc font-hl-roman">
              <p>2020 წლის პირველი 6 თვის ანგარიშში შეგიძლიათ გაეცნოთ პასუხისმგებლიანი თამაშის შედეგებს ფაქტებსა და ციფრებში</p>
            </div>

            <div class="menu-btns eb-font-roman">
              <div class="menu-btn">
                <router-link :to="{ name: 'report', hash: '#ProjectsAndTimeline' }">გაეცანი ყველა პროექტს</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="wrapper">
        <div class="report-desc-block" id="reports">
          <p class="title text-font16 font-hl-light">
            ევროპაბეთმა პასუხისმგებლიანი თამაშის ევროპული მოდელის დანერგვა ინდუსტრიაში 2019 წლის ბოლოდან დაიწყო. ბეთსონ ჯგუფის წარმომადგენელი
            საქართველოში ჯგუფის ერთ-ერთ მთავარ ღირებულებას პასუხისმგებლიანი ოპერირების კონცეფციას იზიარებს და ქართულ ბაზარზე ახალი კულტურის შემოტანას
            განაგრძობს.
          </p>
          <div class="logos-container eb-font-bold">
            <div class="logo">
              <img :src="require('@/assets/report-component/thumb1.png')" alt="" />
              <p>პასუხსიმგებლობა კომუნიკაციაში</p>
            </div>
            <div class="logo">
              <img :src="require('@/assets/report-component/thumb2.png')" alt="" />
              <p>პასუხისმგებლობა ბიზნესში</p>
            </div>
            <div class="logo">
              <img :src="require('@/assets/report-component/thumb3.png')" alt="" />
              <p>პასუხისმგებლობა საზოგადოების მიმართ</p>
            </div>
          </div>
          <p class="subtitle text-font16 font-hl-light">არსებული სიტუაციის მიუხედავად პასუხისმგებლიანი პოლიტიკის სამი მიმართულებით იმპლემენტაცია გრძელდება.</p>
        </div>

        <div class="report-projects-block" id="ProjectsAndTimeline">
          <div class="timeline-block">
            <h2 class="content-title text-font48 eb-font-bold" :class="{ 'mb-100': marginDiv }">პროექტები / თაიმლაინი</h2>
            <!-- <p v-if="timelineHeight">{{timelineHeight}}</p> -->
            <div class="tabs" id="tabs">
              <div class="tab-outer" @click="tabEvent(1)">
                <router-link :to="{ name: 'report', hash: '#year2019' }" :class="{ 'active': activeTab === 1 }" class="tab">2019</router-link>
              </div>
              <div class="tab-outer" @click="tabEvent(2)">
                <router-link :to="{ name: 'report', hash: '#year2020' }" :class="{ 'active': activeTab === 2 }" class="tab">2020</router-link>
              </div>
              <div class="tab-outer" @click="tabEvent(3)">
                <router-link :to="{ name: 'report', hash: '#year2021' }" :class="{ 'active': activeTab === 3 }" class="tab">2021</router-link>
              </div>
            </div>
            <div class="timeline-container">
              <div class="report text-font16" :class="'report'+report.id" v-for="report of reports" :key="report.id" :id="report.scrollId ? report.scrollId : ''">
                <img v-if="report.image" :src="require(`@/assets/report-component/${report.image}`)" />

                <iframe v-if="report.video" ref="vimeoPlayer" id="vimeo-player" src="https://player.vimeo.com/video/534314537" width="auto" height="auto" frameborder="0" allowfullscreen></iframe>

                <div class="date-container font-hl-light">
                  <img v-if="report.logo" :src="require(`@/assets/report-component/${report.logo}.png`)" />
                  <p v-if="report.date" class="date">{{ report.date }}</p>
                </div>
                <div v-if="report.title" class="title font-hl-bold">{{ report.title }}</div>
                <p v-if="report.desc" class="desc font-hl-light">{{ report.desc }}</p>
                <a class="font-hl-light text-font14" v-if="report.url" target="_blank" :href="report.url"> <span>მეტის ნახვა</span></a>
                <router-link class="font-hl-light text-font14" v-else :to="{
                    name: 'article',
                    params: {
                      slug: report.route,
                    },
                  }">
                  <span>მეტის ნახვა</span>
                </router-link>
                <div class="month-name text-uppercase eb-font-bold text-font20">{{ report.monthName }}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Player from "@vimeo/player";
import reports from "./reports.json";
import $ from "jquery";
import router from "../../router";

export default {
  name: "ReportComponent",
  data() {
    return {
      LockScene: false,
      reports: reports,
      activeTab: 3,
      marginDiv: false,
    };
  },

  props: {},

  methods: {
    tabEvent(index) {
      this.LockScene = true;
      this.activeTab = index;
      setTimeout(() => {
        this.LockScene = false;
      }, 200);
    },
  },
  computed: {
    ...mapGetters({
      data: "allArticles",
    }),
    timelineHeight() {
      let h = $(".report-projects-block").css("height");
      return parseFloat(h);
    },
  },
  mounted() {
    let reportHeaderHeight =
      document.getElementById("reportHeader").scrollHeight;

    const reportHeader = this.$scrollmagic.scene({
      triggerElement: "#reportHeader",
      triggerHook: 0.5,
      duration: reportHeaderHeight,
    });
    this.$scrollmagic.addScene(reportHeader);
    reportHeader.on("enter", (event) => {
      this.$router
        .replace({
          name: this.$route.name,
          hash: ``,
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    });

    const projectsAndTimeline = this.$scrollmagic
      .scene({
        triggerElement: "#ProjectsAndTimeline",
        triggerHook: 0,
        // duration: this.timelineHeight,
      })
      .setClassToggle("#tabs", "fixed-right");
    this.$scrollmagic.addScene(projectsAndTimeline);

    projectsAndTimeline.on("enter", (event) => {
      this.marginDiv = true;
    });
    const year2021 = this.$scrollmagic.scene({
      triggerElement: "#year2021",
      triggerHook: 0,
      duration: "350%",
    });
    this.$scrollmagic.addScene(year2021);

    year2021.on("enter", (event) => {
      if (!this.LockScene) {
        this.activeTab = 3;
      }
    });

    const year2020 = this.$scrollmagic.scene({
      triggerElement: "#year2020",
      triggerHook: 0,
      duration: "300%",
    });
    this.$scrollmagic.addScene(year2020);

    year2020.on("enter", (event) => {
      if (!this.LockScene) {
        this.activeTab = 2;
      }
    });

    const year2019 = this.$scrollmagic.scene({
      triggerElement: "#year2019",
      triggerHook: 0,
      duration: "300%",
    });
    this.$scrollmagic.addScene(year2019);

    year2019.on("enter", (event) => {
      if (!this.LockScene) {
        this.activeTab = 1;
      }
    });
  },
};
</script>

<style lang="scss">
.report-component {
  width: 100%;
  min-height: 100%;
  //header
  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 599px;
    z-index: 9;
    .header-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/report-component/report-header-bg.jpg");
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center center;
      background-size: cover;
      z-index: -1;
    }
    .header-text {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      .header-text-inner {
        padding: 0px 25px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        .isTitle {
          color: #fcfcfc;
          font-size: 48px;
          line-height: 1.1;
          letter-spacing: -1px;
          margin-bottom: 20px;
        }
        .isDesc {
          color: #fefefe;
          margin-bottom: 35px;
          width: 60%;
          margin-left: auto;
          margin-right: auto;
          .betsson-items {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            .betsson-item {
              color: #fff;
              margin-top: 20px;
              flex: 1;
              .icon {
                font-size: 35px;
                width: auto;
                height: auto;
                line-height: auto;
              }

              .betsson-item-img {
                margin-bottom: 20px;
              }
              .betsson-item-count {
                font-size: 40px;
                margin-bottom: 10px;
              }
              .betsson-item-text {
                font-size: 16px;
              }
            }
          }
        }
        .menu-btns {
          display: flex;
          justify-content: center;
          align-items: center;
          .menu-btn {
            border: 1px solid #fff;
            border-radius: 50px;
            margin-right: 10px;
            padding: 14px 30px;
            color: #fff;
            font-size: 13px;
            background-color: transparent;
            a {
              color: #fff;
            }
            &:hover {
              background-color: rgba(0, 162, 175, 1);
              border: 1px solid rgba(0, 162, 175, 1);
              cursor: pointer;
            }
          }
        }
        @media only screen and (max-width: 1200px) {
          padding: 0px 15px;
        }
        @media only screen and (max-width: 880px) {
          padding: 0px 15px;
        }
        @media only screen and (max-width: 768px) {
          padding: 0px 15px;
          padding-top: 40px;
          width: 100%;
          .isTitle {
            font-size: 30px;
          }
          .isDesc {
            display: none;
          }
          .menu-btns {
            display: none;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      min-height: 262px;
    }
  }
  .timeline-block {
    .content-title {
      color: #303132;
      margin-bottom: 50px;
    }
  }
  // end header
  .tabs {
    margin-top: 20px;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
    transition: all 0.35s linear;
    .tab {
      width: 115px;
      height: 50px;
      background: #e3e3e3;
      color: #00abb2;
      text-align: center;
      float: right;
      font-size: 20px;
      margin-bottom: 5px;
      line-height: 50px;
      border-radius: 5px;
      margin: 0 10px;
      transition: all 0.35s linear;
      cursor: pointer;
      &.active {
        background-color: rgba(0, 162, 175, 1);
        color: #fff;
      }
      &:hover {
        width: 180px;
        // background-color: rgba(0, 162, 175, 1);
        // color: #fff;
      }
    }
    &.fixed-right {
      transition: all 0.35s linear;
      position: fixed;
      right: 0;
      top: 200px;
      flex-direction: column;
      z-index: 999;
      margin-top: 0;
      margin-bottom: 0;
      .tab-outer {
        &:nth-child(1) {
          order: 3;
        }
        &:nth-child(2) {
          order: 2;
        }
        &:nth-child(3) {
          order: 1;
        }
      }
      .tab {
        margin: 4px 10px;
        &:hover {
          width: 180px;
          background-color: rgba(0, 162, 175, 1);
          color: #fff;
        }
      }
    }
  }
  .timeline-container {
    display: grid;
    grid-template-columns: 40% 40%;
    column-gap: 127px;
    row-gap: 0;
    justify-content: center;
    padding-bottom: 20px;
    position: relative;

    #vimeo-player {
      width: 100%;
      height: 286px;
    }
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: #00abb2;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }

    :nth-child(odd) {
      &.report {
        position: relative;
        opacity: 0;
        transition: all 0.35s linear;
        .month-name {
          position: absolute;
          right: -92px;
          top: 276px;
          background-color: #fff;
          z-index: 9;
          padding: 10px;
        }
        &.fade-in {
          opacity: 1;
          // -webkit-animation: fadeIn 1s linear;
          // animation: fadeIn 1s linear;
        }
        &::after {
          content: "";
          position: absolute;
          height: 20px !important;
          width: 20px !important;
          background-color: #fff !important;
          border-right: 1px solid #bbb !important;
          border-top: 1px solid #bbb !important;
          left: auto;
          right: -11px;
          transform: rotate(45deg);
          z-index: 999 !important;
          top: 320px;
          bottom: auto !important;
        }
        &::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid #ff851c;
          bottom: 0;
          right: -75px;
          top: 320px;
          background-color: #fff;
          z-index: 9;
        }
      }
    }
    :nth-child(even) {
      &.report {
        position: relative;
        margin-top: 80px;
        opacity: 0;
        transition: all 0.35s linear;
        .month-name {
          position: absolute;
          left: -95px;
          top: 276px;
          background-color: #fff;
          z-index: 9;
          padding: 10px;
        }
        &.fade-in {
          opacity: 1;
          // -webkit-animation: fadeIn 1s linear;
          // animation: fadeIn 1s linear;
        }
        &::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid #ff851c;
          bottom: 0;
          left: -75px;
          top: 320px;
          background-color: #fff;
          z-index: 9;
        }
        &::after {
          content: "";
          position: absolute;
          height: 20px !important;
          width: 20px !important;
          background-color: #fff !important;
          border-right: 1px solid #bbb !important;
          border-top: 1px solid #bbb !important;
          right: auto;
          transform: rotate(225deg);
          z-index: 999 !important;
          left: -11px;
          top: 320px;
          bottom: auto !important;
        }
      }
    }

    .report {
      border: 1px solid #bbb;
      display: flex;
      flex-direction: column;
      text-align: left;
      height: fit-content;
      .month-name {
        color: #00abb2;
      }
      a {
        color: #00abb2;
        padding: 0 25px 20px 25px;
        span {
          text-decoration: underline;
        }
      }
      .date-container {
        display: flex;
        align-items: center;
        padding: 25px;
        img {
          width: 35px;
          margin-right: 20px;
        }
      }

      .title {
        text-align: left;
        padding: 0 25px;
        font-size: 19px;
        color: #292929;
      }
      .desc {
        text-align: left;
        padding: 25px;
        font-size: 15px;
      }
    }
    @media only screen and (max-width: 1000px) {
      grid-template-columns: 100%;
      .report {
        margin-left: 100px;
        margin-top: 0 !important;
        margin-bottom: 30px;
        width: 498px;
      }
      &::after {
        left: 20px !important;
        margin-left: 0 !important;
      }
      :nth-child(odd) {
        &.report {
          position: relative;
          .month-name {
            right: auto;
            left: -108px !important;
            width: fit-content;
            top: 376px !important;
          }
          &::after {
            right: auto !important;
            left: -11px !important;
            transform: rotate(226deg) !important;
            top: 420px !important;
          }
          &::before {
            left: -90px !important;
            right: auto !important;
            top: 420px !important;
          }
        }
      }
      :nth-child(even) {
        &.report {
          position: relative;
          margin-top: 80px;
          .month-name {
            left: -108px !important;
            width: fit-content;
            top: 376px !important;
          }
          &::before {
            left: -90px !important;
            top: 420px !important;
          }
          &::after {
            right: auto !important;
            left: -11px !important;
            transform: rotate(226deg) !important;
            top: 420px !important;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .report {
        margin-left: 60px;
        margin-top: 0 !important;
        margin-bottom: 30px;
        width: 388px;
        border-right: none;
        .date-container {
          padding: 25px 40px 10px 40px;
        }
        .title,
        .desc,
        a {
          padding-left: 40px;
        }
        .desc {
          font-size: 15px;
          padding-top: 30px;
        }
        a {
          font-size: 14px;
        }
      }
      &::after {
        left: 20px !important;
        margin-left: 0 !important;
      }
      .text-font48 {
        font-size: 30px !important;
      }
      :nth-child(odd) {
        &.report {
          .month-name {
            right: auto;
            left: -70px !important;
            width: fit-content;
            top: 376px !important;
          }
          &::after {
            right: auto !important;
            left: -11px !important;
            transform: rotate(226deg) !important;
            top: 420px !important;
          }
          &::before {
            left: -50px !important;
            right: auto !important;
            top: 420px !important;
          }
        }
      }
      :nth-child(even) {
        &.report {
          position: relative;
          margin-top: 80px;
          .month-name {
            left: -70px !important;
            width: fit-content;
            top: 376px !important;
          }
          &::before {
            left: -50px !important;
            top: 420px !important;
          }
          &::after {
            right: auto !important;
            left: -11px !important;
            transform: rotate(226deg) !important;
            top: 420px !important;
          }
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .report {
        margin-left: 0;
        width: 100%;
        border-right: 1px solid #bbb;

        .month-name {
          display: none;
        }
        &::after {
          display: none;
        }
        &::before {
          display: none;
        }
      }
      &::after {
        display: none;
      }
    }
  }
  .report {
    iframe {
      background: #000;
    }
  }

  //content
  .content {
    .report-desc-block {
      padding-top: 80px;
      padding-bottom: 0px;
      text-align: center;
      background-color: transparent;
      // max-width: 880px;
      margin: 0 auto;
      scroll-margin-top: 36px;
      .title {
        color: #878787;
        line-height: 1.7;
        margin-bottom: 20px;
        font-size: 16px;
      }
      .subtitle {
        color: #878787;
        font-size: 16px;
        line-height: 1.7;
        max-width: 600px;
        margin: 0 auto;
      }
      .logos-container {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding: 0px 0px 40px 0px;
        width: 100%;
        img {
          margin-bottom: 60px;
        }
        .logo {
          // max-width: 343px;
          flex: 1;
          padding: 20px 20px 0;
        }
        p {
          font-size: 18px;
          line-height: 1.1;
          color: #203132;
        }
        @media only screen and (max-width: 880px) {
          flex-direction: column;
        }
      }
      @media only screen and (max-width: 1024px) {
        padding-top: 60px;
        padding-bottom: 0px;
      }
      @media only screen and (max-width: 768px) {
        padding-top: 50px;
        padding-bottom: 0px;
        width: 100%;
        max-width: 440px;
        margin: 0 auto;
      }
      @media only screen and (max-width: 480px) {
        scroll-margin-top: 16px;
      }
    }
    .report-projects-block {
      padding-top: 90px;
      padding-bottom: 90px;
      text-align: center;
      background-color: transparent;
      height: 100%;
      scroll-margin-top: 36px;
      &.red {
        background-color: red;
      }
      @media only screen and (max-width: 1024px) {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      @media only screen and (max-width: 768px) {
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
        max-width: 440px;
        margin: 0 auto;
      }
      @media only screen and (max-width: 480px) {
        scroll-margin-top: 16px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .text-font48 {
      font-size: 30px !important;
    }
    .logos-container {
      .logo {
        margin-bottom: 20px;
        img {
          margin-bottom: 10px !important;
        }
        p {
          font-size: 15px !important;
        }
      }
    }
    .tabs {
      margin-bottom: 50px;
      margin-top: 60px;
      .tab {
        width: 75px !important;
        margin: 0px 5px;
        &:hover {
          font-size: 25px;
        }
      }
    }
  }
  //end content
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
</style>
