export default {
  emitLogin: function (origin = null) {
    const parent = window.parent;
    if (parent) {
        parent.postMessage('Login', !origin ? '*' : origin);
    }
  },
  emitPostMessage: function (message, origin = null) {
    const parent = window.parent;
    if (parent) {
        parent.postMessage(message, !origin ? '*' : origin);
    }
  },
  LoadingFinished:function(){
    this.emitPostMessage({type: 'promotionsIframe',action:'ready'}, null);
  }
};
