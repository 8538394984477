<template>
  <footer class="footer-container">
    <div class="wrapper">
      <div class="footer-wrapper text-font14">
        <div class="box betsson-box">
          <img class="betssonLogo" @click="backToTop()" :src="require('@/assets/footer-component/betssongroup.png')" alt="" />
          <p>
            ბეთსონ ჯგუფს 50 წელზე მეტი გამოცდილება აქვს აზარტული თამაშების ბაზარზე. ევროპაბეთი კი უკვე მეთხუთმეტე წელს აღნიშნავს გემბლინგში და მეხუთე
            წელი ხდება, რაც ბეთსონ ჯგუფის წევრია. მოთამაშეებისთვის პასუხისმგებლიანი და სამართლიანი თამაშის შეთავაზება ჩვენთვის მთავარი მიზანია. მხოლოდ
            ასე შევძლებთ ბიზნესის წარმოებას ბედნიერ, ჯანმრთელ მოთამაშეებთან, რომლებიც ათწლეულების მანძილზე სიამოვნებას იღებენ ჩვენთან თამაშით.
          </p>
        </div>
        <div class="box menu-box">
          <ul>
            <li @click="$route.name == 'main' ? backToTop() : null"><router-link :to="{ name: 'main' }" exact exact-path>მთავარი</router-link></li>
            <li @click="$route.name == 'about' ? backToTop() : null"><router-link :to="{ name: 'about' }">ჩვენ შესახებ</router-link></li>
            <li @click="$route.name == 'responsibleGaming' ? backToTop() : null"><router-link :to="{ name: 'responsibleGaming' }">პასუხისმგებლიანი თამაში</router-link></li>
            <li @click="$route.name == 'csr' ? backToTop() : null"><router-link :to="{ name: 'csr' }">საზოგადოებრივი პროექტები</router-link></li>
            <li @click="$route.name == 'collaborations' ? backToTop() : null"><router-link :to="{ name: 'collaborations' }">პარტნიორები</router-link></li>
          </ul>
        </div>
        <div class="box social-box">
          <div class="social">
            <a href="https://www.facebook.com/europebet" target="_blank" rel="noopener noreferrer">
              <img class="social-icon" :src="require('@/assets/footer-component/facebook.svg')" alt="" /></a>

            <a href="https://www.instagram.com/europebet/" target="_blank" rel="noopener noreferrer">
              <img class="social-icon" :src="require('@/assets/footer-component/instagram.svg')" alt="" /></a>
            <a href="https://www.linkedin.com/company/europebet/" target="_blank" rel="noopener noreferrer">
              <img class="social-icon" :src="require('@/assets/footer-component/linkedin.svg')" alt="" />
            </a>
          </div>
          <div class="info">
            <a target="_blank" href="tel://+995*0909/" class="info-item">
              <img :src="require('@/assets/footer-component/phone-1.png')" alt="" />
              <span>*0909</span>
            </a>
            <a target="_blank" href="tel://+995032-2194000/" class="info-item">
              <img :src="require('@/assets/footer-component/phone-2.jpg')" alt="" />
              <span>032 2194000</span>
            </a>
            <a class="info-item gmail">
              <img :src="require('@/assets/footer-component/mail.jpg')" alt="" />
              <span> info@responsible.ge</span>
            </a>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p>Copyright © 2023 Europebet.com. ყველა უფლება დაცულია.</p>
      </div>
    </div>
  </footer>
</template>

<script>
import router from "../../router";
export default {
  name: "FooterComponent",
  props: {
    backToTop: Function,
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
.footer-container {
  padding-top: 21px;
  padding-bottom: 21px;
  background-color: #262729;
  color: #969696;
  text-align: center;
  width: 100%;
  font-size: 13px;
  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 70px 0;
    .box {
      flex: 1;
      line-height: 24px;
      &.betsson-box {
        text-align: left;
        margin-top: -14px;
        .betssonLogo {
          cursor: pointer;
        }
        img {
          margin-bottom: 15px;
        }
        p {
          padding-right: 20px;
        }
      }
      &.menu-box {
        margin-left: 5%;
        width: 30%;
      }
      ul {
        text-align: left;
        li {
          margin-bottom: 10px;
          position: relative;
          a {
            color: #878787;
            transition: all 0.5s;
            &:hover {
              color: rgba(0, 162, 175, 1);
              transition: all 0.5s;
              margin-left: 10px;
            }
          }
          &::before {
            content: "\e732";
            font-family: "icomoon";
            display: inline-block;
            margin-left: -25px;
            width: 25px;
            font-size: 14px;
          }
          &:has(> a.router-link-active) {
            &::before {
              color: rgba(0, 162, 175, 1);
            }
          }
        }
      }
      &.social-box {
        display: flex;
        flex-direction: column;
        .social {
          margin-bottom: 40px;
        }
      }
      .social-icon {
        width: 34px;
        float: left;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 2px;
        margin-right: 15px;
        padding: 4px;
      }
      .info {
        display: flex;
        flex-direction: column;

        .info-item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          color: #878787;
          &.gmail {
            pointer-events: none;
          }
          span {
            margin-left: 15px;
            &:hover {
              color: rgba(0, 162, 175, 1);
            }
          }
        }
      }
    }
  }
  .copyright {
    text-align: left;
  }
  @media only screen and (max-width: 768px) {
    .footer-wrapper {
      flex-direction: column;
      padding: 40px 0;
      .copyright {
        margin-top: 15px;
        text-align: center;
        margin-left: auto;
      }
      .betsson-box {
        margin-bottom: 40px;
      }
      .menu-box {
        margin-left: 22px !important;
        margin-bottom: 40px;
        width: 100% !important;
      }
    }
  }
}
</style>
