<template>
  <div class="csr-component" id="csrComponent">
    <div class="header">
      <div class="header-bg" id="csrHeader"></div>
      <div class="wrapper">
        <div class="header-text">
          <div class="header-text-inner">
            <h3 class="isTitle font-hl-bold">ჩვენ ვზრუნავთ</h3>
            <div class="isDesc font-hl-roman">
              <p>
                როგორც ევროპაბეთი, ასევე მთლიანად ბეთსონ ჯგუფი ვცდილობთ საზოგადოების აქტიური წევრები ვიყოთ. ჩვენი კომპანიის ერთ-ერთი ღირებულება
                „ერთიანი ბეთსონია“ და სწორედ ერთად ვცდილობთ უკეთესობისკენ შევცვალოთ რეალობა.
              </p>
            </div>
            <div class="menu-btns font-hl-roman">
              <div class="menu-btn">
                <router-link :to="{ name: 'csr', hash: '#projects' }">ნახე ჩვენი პროექტები</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="wrapper">
        <div class="projects-block" id="projects">
          <h3 class="title text-font30 font-hl-bold">სოციალური საკითხების მხარდაჭერა ევროპაბეთში</h3>
          <p class="subtitle text-font18 font-hl-light">
            როგორც მუდმივად ზრდასა და განვითარებაზე ორიენტირებული კომპანია, ევროპაბეთი მაქსიმალურად ცდილობს საზოგადოებაზე დადებითი გავლენა მოახდინოს.
            ჩვენი პოლიტიკა მოიცავს ქველმოქმედებასა და სხვადასხვა აქტივობებში მონაწილეობას. ჩვენს სამუშაო ძალას კეთილი საქმისთვის ვიყენებთ. ერთად კიდევ
            უფრო მეტს შევძლებთ.
          </p>
          <div class="thumbs-boxes" id="boxes">
            <div class="box" v-for="item in showReports" :key="item.id" :class="item.background">
              <router-link :to="{
                  name: 'article',
                  params: {
                    slug: item.slug,
                  },
                }">
                <div class="box-overlay">
                  <div class="thumb-title font-hl-roman">{{ item.title }}</div>
                  <p class="country font-hl-light">{{ item.country }}</p>
                </div>
              </router-link>
            </div>
            <div id="infiniteScroll"></div>
          </div>

          <div id="loader">
            <div class="loader"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import { mapGetters } from "vuex";

import $ from "jquery";
export default {
  name: "CsrComponent",
  data() {
    return {
      csrSectionObserver: null,
      showReports: [],
    };
  },
  methods: {},
  computed: {
    ...mapGetters({
      articles: "allArticles",
    }),
  },

  mounted() {
    let array = [...this.articles];
    const chunkSize = 6;
    const result = [];
    while (array.length > 0) {
      result.push(array.splice(0, chunkSize));
    }
    this.showReports.push(...result[0]);
    // console.log(result, this.showReports);
    const loader = this.$scrollmagic.scene({
      triggerElement: "#infiniteScroll",
      triggerHook: 0,
    });

    this.$scrollmagic.addScene(loader);
    let num = 0;
    loader.on("enter", (event) => {
      num++;
      if (num < result.length) {
        this.showReports.push(...result[num]);
      }
      if (num == result.length - 1) {
        document.getElementById("loader").style.display = "none";
      }
    });

    let csrHeaderHeight = document.getElementById("csrHeader").offsetHeight;

    const csrHeader = this.$scrollmagic.scene({
      triggerElement: "#csrHeader",
      triggerHook: 0.5,
      duration: csrHeaderHeight,
    });
    this.$scrollmagic.addScene(csrHeader);
    csrHeader.on("enter", (event) => {
      this.$router
        .replace({
          name: this.$route.name,
          hash: ``,
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    });
  },
};
</script>
<style lang="scss">
.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;

  margin: 0 auto;
  border-top: 10px solid #3498db;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
#infiniteScroll {
  margin-top: -100px;
}
.csr-component {
  width: 100%;
  min-height: 100%;
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 552px;
    z-index: 9;
    .header-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/crs-component/crs-header-bg.jpg");
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center right;
      background-size: cover;
      z-index: -1;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
      }
    }
    .header-text {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      .header-text-inner {
        padding: 0px 25px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        .isTitle {
          color: #fcfcfc;
          font-size: 48px;
          line-height: 1.1;
          letter-spacing: -1px;
          margin-bottom: 20px;
        }
        .isDesc {
          color: #fefefe;
          font-size: 16px;
          margin-bottom: 30px;
          p {
            margin-bottom: 20px;
            span {
              font-style: italic;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        .menu-btns {
          display: flex;
          justify-content: center;
          align-items: center;
          .menu-btn {
            border: 1px solid #fff;
            border-radius: 50px;
            margin-right: 10px;
            padding: 14px 30px;
            font-size: 13px;
            color: #fff;
            background-color: transparent;
            a {
              color: #fff;
            }
            &:hover {
              background-color: rgba(0, 162, 175, 1);
              border: 1px solid rgba(0, 162, 175, 1);
              cursor: pointer;
            }
          }
        }
        @media only screen and (max-width: 1200px) {
          padding: 0px 15px;
          width: 80%;
        }
        @media only screen and (max-width: 1024px) {
          .isDesc {
            margin-bottom: 0px;
          }
          .menu-btn {
            margin-top: 70px;
          }
        }
        @media only screen and (max-width: 880px) {
          width: 90%;
        }
        @media only screen and (max-width: 768px) {
          width: 100%;
          max-width: 440px;
          margin: 0 auto;
          padding: 0px 15px;
          .isTitle {
            font-size: 30px;
          }
          .isDesc {
            display: none;
          }
          .menu-btns {
            margin-top: 0px;
            display: none;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      min-height: 262px;
    }
  }

  .content {
    .projects-block {
      padding-top: 80px;
      padding-bottom: 80px;
      text-align: center;
      background-color: transparent;
      scroll-margin-top: 36px;
      .title {
        color: #303132;
        line-height: 1.1;
        margin-bottom: 20px;
        font-size: 30px;
      }
      .subtitle {
        color: #878787;
        font-size: 18px;
        line-height: 1.7;
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 40px;
      }
      .thumbs-boxes {
        padding: 45px 0px 90px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
        max-width: 1160px;
        margin: 0 auto;
        .box {
          height: 374px;
          width: 374px;
          background-size: cover;
          position: relative;
          transition: all 0s;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 5px;
          transition: all 1s;
          &.thumb1 {
            background-image: url("../../assets/crs-component/thumb1.jpg");
          }
          &.thumb2 {
            background-image: url("../../assets/crs-component/thumb2.jpg");
          }
          &.thumb3 {
            background-image: url("../../assets/crs-component/thumb3.jpg");
          }
          &.thumb4 {
            background-image: url("../../assets/crs-component/thumb4.jpg");
          }
          &.thumb5 {
            background-image: url("../../assets/crs-component/thumb5.jpg");
          }
          &.thumb6 {
            background-image: url("../../assets/crs-component/thumb6.jpg");
          }
          &.thumb7 {
            background-image: url("../../assets/crs-component/thumb7.jpg");
          }
          &.thumb8 {
            background-image: url("../../assets/crs-component/thumb8.jpg");
          }
          &.thumb9 {
            background-image: url("../../assets/crs-component/thumb9.jpg");
          }
          &.thumb10 {
            background-image: url("../../assets/crs-component/thumb10.jpg");
          }
          &.thumb11 {
            background-image: url("../../assets/crs-component/thumb11.jpg");
          }
          &.thumb12 {
            background-image: url("../../assets/crs-component/thumb12.png");
          }
          &.thumb13 {
            background-image: url("../../assets/crs-component/thumb13.jpg");
          }
          &.thumb14 {
            background-image: url("../../assets/crs-component/thumb14.jpg");
          }
          &.thumb15 {
            background-image: url("../../assets/crs-component/thumb15.png");
          }
          &.thumb16 {
            background-image: url("../../assets/crs-component/thumb16.png");
          }
          &.thumb17 {
            background-image: url("../../assets/crs-component/thumb17.jpg");
          }
          &.thumb18 {
            background-image: url("../../assets/crs-component/thumb18.jpg");
          }
          &.thumb19 {
            background-image: url("../../assets/crs-component/thumb19.jpg");
          }
          &.thumb20 {
            background-image: url("../../assets/crs-component/thumb20.jpg");
          }
          &.thumb21 {
            background-image: url("../../assets/crs-component/thumb21.jpg");
          }
          &.thumb22 {
            background-image: url("../../assets/crs-component/thumb22.jpg");
          }
          &.thumb23 {
            background-image: url("../../assets/crs-component/thumb23.jpg");
          }
          &.thumb24 {
            background-image: url("../../assets/crs-component/thumb24.jpg");
          }
          &.thumb25 {
            background-image: url("../../assets/crs-component/thumb25.jpg");
          }
          &.thumb26 {
            background-image: url("../../assets/crs-component/thumb26.jpg");
          }
          @media only screen and (max-width: 1220px) {
            height: 300px;
            width: 300px;
          }
          @media only screen and (max-width: 1024px) {
            height: 290px;
            width: 290px;
          }
          @media only screen and (max-width: 959px) {
            height: 234px;
            width: 234px;
            .thumb-title {
              font-size: 12px;
              padding: 5px;
            }
          }
          @media only screen and (max-width: 600px) {
            height: 280px;
            width: 280px;
          }
          .thumb-title {
            border: 3px solid;
            padding: 6px 15px;
            margin-bottom: 10px;
            color: #fff;
            z-index: 9;
            position: relative;
            line-height: 20px;
            width: 80%;
          }
          .country {
            text-transform: none;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0;
            color: #fff;
            z-index: 9;
            position: relative;
          }
          .box-overlay {
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
            left: 0;
            transition: all 1s;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &:hover {
              background-color: #00a2af;
              cursor: pointer;
              transition: all 0.1s;
            }
          }
        }
        @media only screen and (max-width: 1200px) {
          justify-content: center;
        }
      }
      @media only screen and (max-width: 1024px) {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      @media only screen and (max-width: 768px) {
        padding-top: 50px;
        padding-bottom: 50px;

        .title {
          max-width: 440px;
          margin-left: auto;
          margin-right: auto;
          font-size: 25px;
        }
        .subtitle {
          max-width: 440px;
        }
      }
      @media only screen and (max-width: 480px) {
        scroll-margin-top: 16px;
      }
    }
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
