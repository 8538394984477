import Axios from "axios";
import CoreService from "../services/core.service";

export default () => {
  Axios.interceptors.request.use(
    (config) => {
      config.headers["brandId"] = "2b081c6c-3ca7-4be7-85b6-48f948cf8de5";
      config.headers["Content-Type"] = "application/json";
      config.headers["x-obg-channel"] = "Web";
      config.headers["x-obg-device"] = CoreService.isMobile()
        ? "Mobile"
        : "Desktop";
      config.headers["marketCode"] = "ka";
     // config.headers["CustomerIdTest"] = "19510d6f-14aa-43eb-9029-92b7cef7dd0a";

      
      const token = localStorage.getItem('sessionToken');
      if (token && token!=="null") {
        console.log("TOKEN EXISTS" )
        config.headers["sessiontoken"] = token;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );

  Axios.interceptors.response.use(
    (response) => response,
    async (err) => {
      if (process.env.VUE_APP_HTTP_ERROR_LOGS === "true") {
      //  console.log(
      //     `============= Http response: [STATUS = ${err?.response?.status}] =============`
      //   );
        //console.log(err);
        //console.log(err.config);
        //console.log(err.response);
      }
      return Promise.reject(err);
    }
  );
};
